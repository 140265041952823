import React from 'react';
import { Box, Theme } from '@mui/material';
import { Containers } from '../../../styles/AppClip';
import Flex from '../../Common/Flex';
import { Spacer, TextField } from '../index';

const styles = {
  labelField: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '15px',
    lineHeight: '20px',
    marginTop: '0px',
    marginBottom: '4px',
    textTransform: 'none' as const,
  }),
  subLabelField: (theme: Theme) => ({
    ...theme.typography.h5,
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '13px',
    marginTop: '0px',
    textTransform: 'none' as const,
  }),
  fieldStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
  },
  errorMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '0.7rem',
    textAlign: 'right' as const,
    marginTop: '0px',
    marginBottom: '4.5px',
    marginRight: 8,
  }),
};

interface PaymentField {
  id: string;
  placeholder: string;
  autoComplete?: string;
  dataTestId?: string;
}

interface Props {
  paymentCardFields: PaymentField[];
  onFocus: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur: (event: React.FocusEvent<HTMLInputElement>) => void;
  onInput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  isFieldFocus: { [key: string]: boolean };
  getFieldValue: (id: string) => string;
  fieldError: { [x: string]: string | undefined };
  isLoading: boolean;
  theme: Theme;
}

const SenderForm: React.FC<Props> = ({
  paymentCardFields,
  onFocus,
  onBlur,
  onInput,
  isFieldFocus,
  getFieldValue,
  fieldError,
  isLoading,
  theme,
}) => {
  return (
    <Flex style={Containers.column}>
      <Box component="p" sx={styles.labelField(theme)}>
        Your information (for gift message and receipt)
      </Box>
      {paymentCardFields.map((paymentField, index) => {
        const { id, placeholder, autoComplete = 'on', dataTestId } = paymentField;
        const notLastIndex = index !== paymentCardFields.length - 1;
        return (
          <Flex style={Containers.column} key={id}>
            <TextField
              id={id}
              data-testid={dataTestId ?? `${id}-field`}
              placeholder={placeholder}
              style={styles.fieldStyle}
              revertColor={true}
              onFocus={onFocus}
              onBlur={onBlur}
              onInput={onInput}
              isFocusField={isFieldFocus[id]}
              autoFocus={isFieldFocus[id]}
              value={getFieldValue(id)}
              errorMessage={fieldError[id]}
              autoComplete={autoComplete}
              disabled={isLoading}
            />
            {!!notLastIndex && <Spacer height={'3px'} />}
          </Flex>
        );
      })}
    </Flex>
  );
};

export default SenderForm;

import { makeStyles } from '@material-ui/core';
import { FC } from 'react';
import { Typography } from '../../styles/AppClip';
import Flex from '../Common/Flex';

interface TotalFooter {
  totalAmount?: string;
  subTotal?: string;
  fee?: string;
  gst?: string;
}

const useStyles = makeStyles(() => ({
  regularLabel: {
    ...Typography.rubik.regular,
    flex: 1,
    color: '#000006',
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
  },
  regularValue: {
    ...Typography.rubik.semiBold,
    flex: 2,
    color: 'var(--text-offBlackOnLight-80, rgba(23, 33, 54, 0.80))',
    textAlign: 'end',
    fontSize: '16px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
  },
  totalLabel: {
    ...Typography.rubik.bold,
    flex: 1,
    color: 'var(--text-darkOnLight-80, rgba(0, 0, 0, 0.80))',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
    fontWeight: 700,
  },
  totalValue: {
    ...Typography.rubik.bold,
    flex: 2,
    color: 'var(--text-offBlackOnLight-100, #172136)',
    textAlign: 'end',
    fontSize: '18px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
    fontWeight: 700,
  },
  regularText: {
    ...Typography.rubik.regular,
    flex: 1,
    color: '#000006',
    fontSize: '14px',
    lineHeight: '21px',
    marginTop: '1px',
    marginBottom: '1px',
  },
}));

export const TotalComponent: FC<TotalFooter> = ({ totalAmount, subTotal, fee, gst }) => {
  const classes = useStyles();
  return (
    <>
      <Flex
        style={{
          flexDirection: 'row',
          marginTop: 24,
          padding: '0px 0px 0px 0px', // top,right,bottom,left
        }}
      >
        <p className={classes.regularLabel}>Subtotal</p>
        <p className={classes.regularValue}>{'$' + subTotal}</p>
      </Flex>
      <Flex
        style={{
          flexDirection: 'row',
          marginTop: 10,
          padding: '0px 0px 0px 0px', // top,right,bottom,left
        }}
      >
        <p className={classes.regularLabel}>Purchase Fee</p>
        <p className={classes.regularValue}>{'$' + fee}</p>
      </Flex>
      <Flex
        style={{
          flexDirection: 'row',
          marginTop: 10,
          padding: '0px 0px 0px 0px', // top,right,bottom,left
        }}
      >
        <p className={classes.regularLabel}>GST (included)</p>
        <p className={classes.regularValue}>{'$' + gst}</p>
      </Flex>
      <Flex
        sx={{
          flexDirection: 'row',
          marginTop: 10,
          padding: '0px', // top, right, bottom, left
        }}
      >
        <p className={classes.totalLabel}>Total</p>
        <p className={classes.totalValue}>
          <span className={classes.regularText}>AUD</span>
          <span className={classes.totalValue}>{'$' + totalAmount}</span>
        </p>
      </Flex>
    </>
  );
};

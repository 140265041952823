import { Box, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { FC } from 'react';
import { STEPS } from '../../constants';
import { Containers } from '../../styles/AppClip';
import Flex from '../Common/Flex';
import { StepBarProps } from './types';

const styles = {
  stepLabel: (theme: Theme) => ({
    ...theme.typography.caption,
    color: '#454545',
    fontSize: '15px',
    lineHeight: '20px',
    marginTop: 0,
    marginBottom: 0,
  }),
  divCurrentStep: {
    bgcolor: '#757575',
    borderRadius: '8px',
    marginLeft: '8px',
    width: '24px',
    height: '8px',
  },
  divOtherStep: {
    bgcolor: '#C4C4C4',
    borderRadius: '8px',
    marginLeft: '8px',
    width: '8px',
    height: '8px',
  },
};

const StepBar: FC<StepBarProps> = ({ currentStep }) => {
  const theme = useTheme();

  return (
    <Flex
      style={{
        alignContent: 'center',
        alignItems: 'center',
        flexDirection: 'row',
        justifyContent: 'space-between',
        paddingTop: '12px',
        width: '100%',
      }}
    >
      <Box component="p" sx={styles.stepLabel(theme)}>
        {`Step ${currentStep} of ${STEPS.length}`}
      </Box>
      <Flex
        style={{
          ...Containers.row,
          justifyContent: 'flex-end',
        }}
      >
        {STEPS.map((step, index) => (
          <Box key={index} component="div" sx={step === currentStep ? styles.divCurrentStep : styles.divOtherStep} />
        ))}
      </Flex>
    </Flex>
  );
};

export default StepBar;

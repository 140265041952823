/* eslint-disable @typescript-eslint/no-explicit-any */
import { Alert, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import moment from 'moment';
import { FC, useEffect, useMemo, useRef, useState } from 'react';
import { isDesktop } from 'react-device-detect';
import { batch } from 'react-redux';
import { getPurchaseTransaction } from '../../api/AppClip/transaction';
import { BlurLoader, ErrorComponent, ErrorModalComponent, SheetHeaderLabel } from '../../components/AppClip';
import Footer from '../../components/AppClip/OrderSuccessScreen/Footer';
import Flex from '../../components/Common/Flex';
import {
  AppConfig,
  DEFAULT_TIMEZONE,
  FETCH_ERROR,
  GENERAL_ERRORS,
  ORDER_RECEIPT_FAILURE_RETRY_LIMIT,
  ORDER_RECIEPT_RETRY_LIMIT,
  ORDER_STATUS,
  SCREEN_IDS,
} from '../../constants';
import {
  RootState,
  customiseGiftActions,
  paymentActions,
  purchaseActions,
  recipientActions,
  scheduledDeliveryActions,
  screenActions,
  useAppDispatch,
  useAppSelector,
} from '../../store';
import { styles, useStyles } from '../../styles/AppClip/OrderSuccessScreenStyles';
import { CardPurchaseTransaction, ILabels, SEND_DATE_OPTIONS, SEND_OPTIONS } from '../../types';
import { isAppleEnvironment } from '../../utils/helpers';
import { useAppImages } from '../../hooks/useAppImages';
import useInterval from '../../hooks/useInterval';

const { selectAmountScreen, orderSuccessScreen } = SCREEN_IDS;
const { setScreen } = screenActions;
const { resetRecipient } = recipientActions;
const { setSelectedAmount: setSelectedAmountRedux, resetUUID4 } = purchaseActions;
const { resetPayment } = paymentActions;
const { resetAnimation } = customiseGiftActions;
const { reset: resetScheduledDelivery } = scheduledDeliveryActions;

type OrderInfoProps = {
  purchaseTransaction?: CardPurchaseTransaction;
  isLoading?: boolean;
  totalAmount: number;
};

const SolidBarIcon = () => {
  const classes = useStyles();
  return <Flex className={classes.solidBarIcon} />;
};

const DividerBarIcon = ({ customStyle = {} }) => {
  return <Flex style={styles.dividerBarIcon(customStyle)} />;
};

const OrderInfo = ({ purchaseTransaction, isLoading, totalAmount }: OrderInfoProps) => {
  const [divHeight, setDivHeight] = useState(0);
  const { scheduledDelivery } = useAppSelector((state: RootState) => state.schedule);

  const { orderId, paymentDetails } = purchaseTransaction || {};
  const recipient = purchaseTransaction?.recipient;
  const orderNumberDisplay = orderId;

  const deliveryDate = moment.tz(scheduledDelivery.deliveryDate, DEFAULT_TIMEZONE).format('DD MMM YYYY');
  const deliveryTime = moment.tz(scheduledDelivery.deliveryDate, DEFAULT_TIMEZONE).format('hh:mm A');
  const deliveryStatusDisplay = scheduledDelivery.deliveryOptionDate === SEND_DATE_OPTIONS.NOW ? 'Sent' : deliveryDate;

  const setDivRef = (el: HTMLDivElement) => {
    if (!el) return;
    setDivHeight(el.getBoundingClientRect().height);
  };

  return (
    <>
      <div ref={setDivRef}>
        <Labels data-testid="order-success-screen-orderid" label="Order #" value={orderNumberDisplay} />
        <Labels label="Recipient's full name" value={`${recipient?.firstName} ${recipient?.lastName}`} />
        <Labels label="Payment details" value={`**** ${paymentDetails?.card?.last4 || ''}`} />
        <Labels containerStyle={{ marginBottom: 0 }} label="Total paid" value={`AUD $${totalAmount.toFixed(2)}`} />
        <div style={{ fontSize: '13px', color: 'var(--text-darkOnLight-60, rgba(0, 0, 0, 0.60))' }}>
          Incl. purchase fee & GST
        </div>
        <Labels containerStyle={{ marginTop: 26 }} label="Delivery Status" value={deliveryStatusDisplay} />
        {scheduledDelivery.deliveryOptionDate === SEND_DATE_OPTIONS.SCHEDULED && (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              color: 'var(--text-darkOnLight-60, rgba(0, 0, 0, 0.60))',
            }}
          >
            <div style={{ fontSize: '13px' }}>(AEST time zone)</div>
            <div
              style={{
                marginLeft: 'auto',
                fontSize: '13px',
                color: 'var(--text-offBlackOnLight-100, #172136)',
              }}
            >
              {deliveryTime}
            </div>
          </div>
        )}
      </div>

      {isLoading ? <BlurLoader height={divHeight} /> : null}
    </>
  );
};

const Labels: FC<ILabels> = ({ label, value, containerStyle }) => {
  const theme = useTheme();

  return (
    <Flex style={containerStyle || styles.labelContainer}>
      <Flex style={styles.labelTextContainer}>
        <p style={styles.subLabelStyle(theme)}>{label}</p>
      </Flex>
      <Flex style={styles.labelValueContainer}>
        <p style={styles.subLabelValueStyle(theme)}>{value}</p>
      </Flex>
    </Flex>
  );
};

const OrderSuccessScreen: FC = () => {
  console.log('rerender OrderSuccessScreen');

  const dispatch = useAppDispatch();
  const classes = useStyles();
  const theme = useTheme();

  const { card } = useAppImages();
  const { recipientDetails } = useAppSelector((state: RootState) => state.recipient);
  const { scheduledDelivery } = useAppSelector((state: RootState) => state.schedule);
  const { totalAmount, appClipTransactionId } = useAppSelector((state: RootState) => state.purchase);
  const { sender } = useAppSelector((state: RootState) => state.payment);
  const { firstName, mobileNumberDisplay } = recipientDetails;

  const [purchaseTransaction, setPurchaseTransaction] = useState<CardPurchaseTransaction>();
  const [detailsIntervalDelay, setDetailsIntervalDelay] = useState<number | null>(3000);
  const [queryCount, setQueryCount] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const isLoadingRef = useRef<boolean>(isLoading);
  const [error, setError] = useState<any>(null);
  const queryFailureRetryCount = useRef(0);

  useMemo(() => {
    if (!appClipTransactionId) {
      dispatch(setScreen({ screen: selectAmountScreen, loading: false, updating: false }));
      return;
    }
  }, []);

  const {
    id: errorID = '',
    message: errorMessage = '',
    label: errorLabel = '',
    buttonLabel: errorBtnLabel = '',
  } = error || {};
  const { ErrorOnSubmit, ErrorOnPageLoad, ErrorSystemOnSubmit, ErrorNotFound, ErrorCardCreationFailed } =
    GENERAL_ERRORS;

  const isApple = isAppleEnvironment();

  const recipientContact = useMemo(() => {
    return mobileNumberDisplay || scheduledDelivery?.mobileNumberDisplay || scheduledDelivery?.emailAddress;
  }, [mobileNumberDisplay, scheduledDelivery]);

  //Clean User details session
  const resetStates = () => {
    batch(() => {
      dispatch(resetRecipient());
      dispatch(setSelectedAmountRedux(50));
      dispatch(resetPayment());
      dispatch(resetAnimation());
      dispatch(resetUUID4());
      dispatch(resetScheduledDelivery());
    });
  };

  const onClickContinue = () => {
    resetStates();
    dispatch(
      setScreen({
        screen: selectAmountScreen,
        loading: false,
        updating: false,
        isCreditCard: 0,
        isExpansion: false,
      }),
    );
  };

  const handleCompleted = () => {
    setDetailsIntervalDelay(null);
    setIsLoading(false);
    isLoadingRef.current = false;
  };

  const handleError = (err?: any) => {
    setDetailsIntervalDelay(null);
    if (err?.message === FETCH_ERROR) {
      setError(ErrorOnPageLoad);
      setIsLoading(false);
      isLoadingRef.current = false;
    } else {
      setError(ErrorSystemOnSubmit);
      setIsLoading(true);
      isLoadingRef.current = true;
    }
    dispatch(resetPayment());
  };

  const handleErrorWithRetry = (callback: () => void) => {
    if (queryFailureRetryCount.current < ORDER_RECEIPT_FAILURE_RETRY_LIMIT) {
      queryFailureRetryCount.current += 1;
    } else {
      callback();
    }
  };

  const startQueryPurchaseTransactionDetails = async () => {
    // redirect to first page if appClipTransactionId is not set
    if (!appClipTransactionId) {
      dispatch(setScreen({ screen: selectAmountScreen, loading: false, updating: false }));
      return;
    }

    try {
      // handle status
      const response = await getPurchaseTransaction(appClipTransactionId, AppConfig.APP_ID);
      const data = response?.data;
      if (data && response?.status === 'success') {
        setPurchaseTransaction(data);
        const isCreated = data.orderStatus === ORDER_STATUS.CREATED;
        const isFailure = data.createCard === 'failure' || data.sendSMS === 'failure' || data.sendEmail === 'failure';

        if (isCreated) {
          handleCompleted();
          return;
        }

        if (isFailure) {
          setDetailsIntervalDelay(null);
          setIsLoading(false);
          isLoadingRef.current = false;
          return;
        }

        setQueryCount(queryCount + 1);
        return;
      } else if (response?.status === 'not_found') {
        setDetailsIntervalDelay(null);
        setError(ErrorNotFound);
        setIsLoading(false);
        isLoadingRef.current = false;
      } else {
        handleErrorWithRetry(handleError);
      }
    } catch (error) {
      handleErrorWithRetry(() => handleError(error));
    }
  };

  const onPressHandler = () => {
    setError(null);
    switch (errorID) {
      case ErrorOnSubmit.id:
        return console.log('Press me ErrorOnSubmit');
      case ErrorOnPageLoad.id:
        return dispatch(setScreen({ screen: orderSuccessScreen, loading: false, updating: false }));
      case ErrorSystemOnSubmit.id:
        return dispatch(setScreen({ screen: selectAmountScreen, loading: false, updating: false }));
      case ErrorNotFound.id:
        return dispatch(setScreen({ screen: selectAmountScreen, loading: false, updating: false }));
      default:
        return;
    }
  };

  // change message depend on purchase status
  const purchaseMessage = useMemo(() => {
    const { orderCreated, metadata } = purchaseTransaction || {};
    const { recipient, delivery } = metadata || {};
    const contact = delivery?.toLowerCase() === 'sms' ? recipient?.mobile : recipient?.email;

    switch (orderCreated) {
      case 'success':
        return `Your Gift Card has been sent to ${firstName || recipient?.firstName} on ${
          recipientContact || contact
        }.`;
      case 'failure':
        return (
          <Alert severity="error" icon={false} className={classes.alert}>
            {ErrorCardCreationFailed.message}
          </Alert>
        );
      default:
        return `We are sending your Gift Card to ${firstName || recipient?.firstName} on ${
          recipientContact || contact
        }.`;
    }
  }, [purchaseTransaction, firstName, recipientContact, ErrorCardCreationFailed, classes]);

  useInterval(startQueryPurchaseTransactionDetails, detailsIntervalDelay);

  useEffect(() => {
    if (queryCount > ORDER_RECIEPT_RETRY_LIMIT) {
      setDetailsIntervalDelay(null);
      setIsLoading(false);
      isLoadingRef.current = false;
    }
  }, [queryCount]);

  return errorID === ErrorOnPageLoad.id ? (
    <Flex className={classes.errorContainer}>
      <ErrorComponent label={errorLabel} message={errorMessage} buttonLabel={errorBtnLabel} onPress={onPressHandler} />
    </Flex>
  ) : (
    <Flex className={classes.mainContainer}>
      {isDesktop && (
        <Flex justifyContent="center">
          <Box component="img" src={card} sx={{ maxWidth: 325, borderRadius: 2, mb: 3.625 }} />
        </Flex>
      )}
      <ErrorModalComponent
        open={[ErrorOnPageLoad.id, ErrorSystemOnSubmit.id, ErrorNotFound.id].includes(errorID)}
        label={errorLabel}
        message={errorMessage}
        buttonLabel={errorBtnLabel}
        onPress={onPressHandler}
      />
      <SolidBarIcon />
      <SheetHeaderLabel
        label={`Thanks ${sender?.firstName}!`}
        subLabel={purchaseMessage}
        labelStyle={{ textAlign: 'center' }}
      />
      <DividerBarIcon />
      <p style={styles.label(theme)}>Order details</p>
      <OrderInfo purchaseTransaction={purchaseTransaction} isLoading={isLoading} totalAmount={totalAmount} />
      <DividerBarIcon customStyle={{ marginTop: 24 }} />
      <Footer onClickContinue={onClickContinue} isApple={isApple} isLoading={isLoading} />
    </Flex>
  );
};

export default OrderSuccessScreen;

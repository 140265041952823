import { Box } from '@mui/material';
import Flex from '../../../components/Common/Flex';
import { AppConfig } from '../../../constants';
import { useIsLargerScreen } from '../../../hooks';
import { useAppImages } from '../../../hooks/useAppImages';

export const MastercardLoading = () => {
  const isLargerScreen = useIsLargerScreen();
  const { logo, spinner } = useAppImages();
  return (
    <Flex
      width="100vw"
      height={isLargerScreen ? 733 : '100vh'}
      justifyContent="center"
      alignItems="center"
      bgcolor="common.white"
    >
      <Flex flexDirection="column" alignItems="center">
        <Box component="img" src={logo} alt={AppConfig.APP_NAME} />
        <Box component="img" src={spinner} width={64} height={64} />
      </Flex>
    </Flex>
  );
};

export const APP_ENVIRONMENT = {
  PROD: 'prod',
  DEV: 'dev',
};

export const AppConfig = {
  AWSENV: process.env.REACT_APP_AWSENV || APP_ENVIRONMENT.PROD,
  AWSBRAND: process.env.REACT_APP_AWSBRAND || 'mastercardgift',
  PARTNER: process.env.REACT_APP_DEFAULT_PTN || 'karta_b2b',
  DEFAULT_MERCHANT_NAME: process.env.REACT_APP_MERCHANT_NAME || 'mastercardgift',
  QRT_CODE: process.env.REACT_APP_DEFAULT_QRT_CODE || '',
  APP_ID: process.env.REACT_APP_ID || 'mastercardgift-main',
  APP_NAME: process.env.REACT_APP_NAME,
  PROGRAM: process.env.REACT_APP_PROGRAM || 'karta_b2b_mastercardgift',
  PAGE_TITLE: process.env.REACT_APP_PAGE_TITLE || 'Mastercard Digital Gift Card',
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL_FROM || 'mastercardgift@karta.com.au',
  ANDROID_APP_URL: process.env.REACT_APP_ANDROID_APP_URL,
  IOS_APP_URL: process.env.REACT_APP_IOS_APP_URL,
  CDN_BASE_URL: process.env.REACT_APP_CDN_URL,
  DEFAULT_THEME: process.env.REACT_APP_DEFAULT_THEME,
  TC_URL: process.env.REACT_APP_TC_URL,
  TERMS_SERVICE_URL: process.env.REACT_APP_TERMS_SERVICE_URL,
  PRIVACY_URL: process.env.REACT_APP_PRIVACY_URL,
  STRIPE_KEY: process.env.REACT_APP_STRIPE_KEY,
  DISABLE_LEAVE_DIALOG: process.env.REACT_APP_DISABLE_LEAVE_DIALOG === 'true',
  API: {
    CARD_BASE_URL: process.env.REACT_APP_CARD_BASE_URL,
    STRIPE_PAYMENT_INTENT: 'stripe/payment-intent',
    STRIPE_PURCHASE: 'stripe/purchase',
  },
  GA_ID: process.env.REACT_APP_GA_ID,
  GA_EVENTS: {
    SELECT_AMOUNT: process.env.REACT_APP_GA_EVENT_STEP_1,
    RECIPIENT_DETAILS: process.env.REACT_APP_GA_EVENT_STEP_2,
    PAYMENT_CARD: process.env.REACT_APP_GA_EVENT_STEP_4,
    SUCCESS: process.env.REACT_APP_GA_EVENT_STEP_5,
  },
};

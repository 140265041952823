import { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { BottomSheet, Container, HeaderBackGroundImage, HeaderImage, StepBar } from '../../components/AppClip';
import { AppConfig, SCREEN_IDS, Screens } from '../../constants';
import ConnectContextProvider from '../../contexts/ConnectContext';
import { useAppImages } from '../../hooks/useAppImages';
import useCloseSession from '../../hooks/useCloseSession';
import { RootState, useAppSelector } from '../../store';
import '../../styles/styles.scss';
import OrderSuccessScreen from './OrderSuccessScreen';
import PaymentCardScreen from './PaymentCardScreen';
import RecipientDetails from './RecipientDetails';
import ReviewOrderScreen from './ReviewOrderScreen';
import ScheduleDeliveryScreen from './ScheduleDelivery';
import SelectAmount from './SelectAmount';

const {
  selectAmountScreen,
  recipientsDetailScreen,
  reviewOrderScreen,
  paymentCardScreen,
  orderSuccessScreen,
  scheduleDeliveryScreen,
} = SCREEN_IDS;

type Props = {
  loading?: boolean;
};

const MainComponent = ({ loading }: Props) => {
  const search = useLocation().search;
  const { background, logo, card } = useAppImages();
  const screenDetailStates = useAppSelector((state: RootState) => state.screen.screenStates);
  const { screen = selectAmountScreen, loading: isLoading, isCreditCard, updating } = screenDetailStates;

  useCloseSession(![selectAmountScreen, orderSuccessScreen].includes(screen));

  const bgColor = useMemo(() => {
    const color = new URLSearchParams(search).get('bg') || '';
    return color.startsWith('#') ? color : `#${color}`;
  }, [search]);

  const getCurrentStep = () => {
    return Screens[screen] || 1;
  };

  const RenderSwitch = () => {
    switch (screen) {
      case selectAmountScreen:
        return <SelectAmount key="SelectAmount" />;
      case recipientsDetailScreen:
        return <RecipientDetails key="RecipientDetails" />;
      case reviewOrderScreen:
        return <ReviewOrderScreen key="ReviewOrderScreen" />;
      case scheduleDeliveryScreen:
        return <ScheduleDeliveryScreen key="ScheduleDeliveryScreen" />;
      case paymentCardScreen:
        return <PaymentCardScreen key="PaymentCardScreen" />;
      case orderSuccessScreen:
        return <OrderSuccessScreen key="OrderSuccessScreen" />;
      default:
        return <SelectAmount key="SelectAmount" />;
    }
  };

  const noStepBar = updating || screen === reviewOrderScreen || screen === orderSuccessScreen;

  return (
    <Container>
      <HeaderImage id={'header-image'} backgroundImage={background} backgroundColor={bgColor} />
      <HeaderBackGroundImage
        merchantLogo={card}
        merchantName={AppConfig.DEFAULT_MERCHANT_NAME}
        screen={screen}
        loading={isLoading}
        selected={isCreditCard === 1}
      />
      <BottomSheet screen={screen} loading={isLoading || loading}>
        {noStepBar ? null : <StepBar currentStep={getCurrentStep()} />}
        <RenderSwitch />
      </BottomSheet>
    </Container>
  );
};

const Index = (props: Props) => {
  return (
    <ConnectContextProvider>
      <MainComponent {...props} />
    </ConnectContextProvider>
  );
};

export default Index;

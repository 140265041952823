import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MAX_CHARACTERS } from '../../constants';
import { IRecipientDetails } from '../../types';

interface RecipientState {
  recipientDetails: IRecipientDetails;
}

const initialState: RecipientState = {
  recipientDetails: {
    firstName: '',
    lastName: '',
    mobile: '',
    mobileNumberDisplay: '',
    giftMessage: '',
    firstNameERR: '',
    lastNameERR: '',
    mobileNumberERR: '',
    giftMessageERR: '',
    giftMessageCharCounter: MAX_CHARACTERS,
  },
};

const { actions, reducer } = createSlice({
  name: 'RecipientState',
  initialState,
  reducers: {
    setRecipient: (state, action: PayloadAction<IRecipientDetails>) => {
      const { payload } = action;
      state.recipientDetails = payload;
    },
    resetRecipient: () => initialState,
  },
});

export const recipientActions = {
  ...actions,
};

export const recipientReducer = reducer;

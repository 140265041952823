import { makeStyles } from '@material-ui/core/styles';
import { FC } from 'react';
import { useAppImages } from '../../hooks/useAppImages';
import Flex from '../Common/Flex';
import { ContainerProps } from './types';

const useStyles = makeStyles((theme) => ({
  // Maybe style the scrollbar in the future
  root: {
    '&::-webkit-scrollbar': {
      width: '0px',
    },
    '&::-webkit-scrollbar-track': {},
    '&::-webkit-scrollbar-thumb': {},
  },
}));

const DesktopView: FC<ContainerProps> = ({ children }) => {
  const { logo, background } = useAppImages();
  const classes = useStyles();

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <Flex alignItems="center" justifyContent="center" bgcolor="common.white" minHeight={57.7}>
        <img src={logo} alt="Logo" />
      </Flex>
      <Flex flexGrow={1} flexDirection={'column'} width={'100%'} height="100%" position="relative">
        <img src={background} style={{ position: 'absolute', width: '100%' }} alt="Background" />
        <Flex className={classes.root} flexDirection={'column'} justifyContent="center" alignItems="center" mt={7}>
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
};

export default DesktopView;

export const BUTTON_IDS = {
  reviewOrder: {
    selectAmountBTN: 'select-amount-BTN-RO',
    nameBTN: 'name-BTN-RO',
    mobileNumberBTN: 'mobile-number-BTN-RO',
    giftMessageBTN: 'gift-message-BTN-RO',
    emailBTN: 'email-BTN-RO',
    deliveryDateBTN: 'deliver-Date-BTN-RO',
    scheduleDeliveryBTN: 'schedule-delivery',
  },
};

export const FIELD_IDS = {
  recipient: {
    firstNameId: 'first-name',
    lastNameId: 'last-name',
    mobileNumberId: 'mobile-number',
    giftMessageId: 'gift-message',
  },
  card: {
    firstCardNameId: 'first-name',
    lastCardNameId: 'last-name',
    emailCardId: 'email-address',
    mobileCardNumberId: 'mobile-number',
    cardNumberId: 'card-number',
    expirationCardDateId: 'card-expiry',
    securityCardCodeId: 'card-cvc',
  },
  scheduleDelivery: {
    date: 'date',
    mobileNumber: 'mobile-number',
    emailAddress: 'email-address',
  },
};

export enum SCREEN_IDS {
  selectAmountScreen = 'selectAmountScreen',
  recipientsDetailScreen = 'RecipientsDetailScreen',
  reviewOrderScreen = 'ReviewOrderScreen',
  paymentCardScreen = 'PaymentCardScreen',
  orderSuccessScreen = 'OrderSuccessScreen',
  scheduleDeliveryScreen = 'ScheduleDeliveryScreen',
}

import Flex from '../Common/Flex';
import DesktopView from './DesktopView';
import MobileView from './MobileView';
import { FC } from 'react';
import { ContainerProps } from './types';
import { useIsLargerScreen } from '../../hooks/useIsMobileView';

const Container: FC<ContainerProps> = ({ children, backgroundColor }) => {
  if (useIsLargerScreen()) {
    return (
      <Flex width="100%" height="100vh">
        <DesktopView backgroundColor={backgroundColor}>{children}</DesktopView>
      </Flex>
    );
  }
  return <MobileView backgroundColor={backgroundColor}>{children}</MobileView>;
};

export default Container;

/* eslint-disable indent */
import { makeStyles } from '@material-ui/core';
import { CircularProgress, Palette, Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import { PaymentIntent, StripeCardNumberElement, StripeError } from '@stripe/stripe-js';
import { FC, useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import {
  FETCH_ERROR,
  FIELD_IDS,
  PAYMENT_ERRORS,
  PAYMENT_REDIRECT_PATH,
  SCREEN_IDS,
  STRIPE_3D_SECURE_POST_MESSAGE,
} from '../../../constants';
import { RootState, paymentActions, screenActions, useAppDispatch, useAppSelector } from '../../../store';
import { Containers } from '../../../styles/AppClip/Containers';
import { IPaymentIntentResponse } from '../../../types';
import '../../AppClip/Payment/checkOutForm.scss';
import { Spacer } from '../../AppClip/index';
import Flex from '../../Common/Flex';
import ButtonSheet from '../ButtonSheet';
import { ErrorMessage } from '../FormComponents';
import { LabelField } from './PaymentMethodButton';
import VISA_LOGO from '../../../images/visa-logo.png';
import MASTERCARD_LOGO from '../../../images/mastercard-logo.png';
import AMEX_LOGO from '../../../images/amex-logo.png';
import GROUP_VECTORS_CARD from '../../../images/group-vectors-card.png';

const { cardNumberInvalid, cardExpiryInvalid, cardCvcInvalid } = PAYMENT_ERRORS;

const useStyles = makeStyles(() => ({
  disableField: {
    pointerEvents: 'none',
  },
}));

const styles = {
  walletButton: {
    backgroundColor: '#000',
    textTransform: 'none',
  },
  cardParentContainer: {
    ...Containers.column,
    marginRight: 8,
    marginLeft: 8,
    marginTop: 8,
  },
  cardNumberParentContainer: {
    ...Containers.column,
    marginBottom: '8px',
  },
  cardImageParentContainer: (palette: Palette, borderColor: string) => ({
    border: `2px solid ${borderColor}`,
    borderRadius: '10px',
    padding: '11px 8px 11px 8px',
    backgroundColor: palette.grey[100],
  }),
  cardImageContainer: (palette: Palette) => ({
    flex: 1,
    backgroundColor: palette.grey[100],
  }),
  cardExpiryParentContainer: {
    ...Containers.column,
    marginRight: '4.5px',
  },
  cardExpiryContainer: {
    flex: 1,
  },
  message: (color: string) => ({
    color,
  }),
  paymentMethodParentContainer: {
    ...Containers.column,
    marginBottom: '12px',
  },
  messageContainer: (isError: boolean) => ({
    ...Containers.column,
    opacity: isError ? 1 : 0,
  }),
  errorTextHeaderMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '1.1rem',
    fontWeight: 500,
    textAlign: 'center' as const,
    alignSelf: 'center' as const,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
  }),
  errorSubTextMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '0.8rem',
    textAlign: 'center' as const,
    marginTop: '9px',
    marginBottom: 0,
    marginRight: 0,
  }),
  loading: {
    color: '#310D55',
    alignSelf: 'center',
    justifyContent: 'center',
  },
};

interface ICheckoutForm {
  setHeading: (text: string) => void;
  setSubHeading: (text: string) => void;
  setScreenLoading?: (isLoading: boolean) => void;
  failCallback: (response: IPaymentIntentResponse) => void;
}

const CheckoutForm: FC<ICheckoutForm> = ({ setSubHeading, setHeading, failCallback }) => {
  const stripe = useStripe();
  const classes = useStyles();
  const theme = useTheme();
  const { palette } = theme;
  const dispatch = useAppDispatch();

  const [message, setMessage] = useState<string | undefined>('');
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [elements, setElements] = useState(useElements());
  const [cardNumberElement, setCardNumberElement] = useState<StripeCardNumberElement>();
  const [cardNumberImages, setCardNumberImages] = useState([VISA_LOGO, MASTERCARD_LOGO, AMEX_LOGO]);

  const [isCompleteCardNumber, setIsCompleteCardNumber] = useState(false);
  const [isCompleteCardExpiry, setIsCompleteCardExpiry] = useState(false);
  const [isCompleteCardCvc, setIsCompleteCardCvc] = useState(false);
  const isComplete = isCompleteCardNumber && isCompleteCardExpiry && isCompleteCardCvc;

  const { totalAmount } = useAppSelector((state: RootState) => state.purchase);
  const { clientSecret, sender } = useAppSelector((state: RootState) => state.payment);
  const { setPayment } = paymentActions;
  const { setScreen } = screenActions;

  const { orderSuccessScreen } = SCREEN_IDS;

  const cardNumberRef = useRef(null);

  const { cardNumberId, expirationCardDateId, securityCardCodeId } = FIELD_IDS.card;
  const [isFieldFocus, setFieldFocus] = useState({
    [cardNumberId]: false,
    [expirationCardDateId]: false,
    [securityCardCodeId]: false,
  });

  const [fieldError, setFieldError] = useState({
    [cardNumberId]: '',
    [expirationCardDateId]: '',
    [securityCardCodeId]: '',
  });

  useEffect(() => {
    setHeading('Card Details');
    setSubHeading('Please input your card details.');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const initLayout = () => {
    if (!stripe || !clientSecret) return;

    const cardStyle = {
      base: {
        fontFamily: theme.typography.fontFamily,
        iconColor: '#c4f0ff',
        fontWeight: 400,
        fontSize: '17px',
        fontSmoothing: 'antialiased',
        backgroundColor: palette.grey[100],
        '::placeholder': {
          color: '',
        },
      },
      focus: {
        borderColor: '#310D55',
      },
      invalid: {
        iconColor: palette.error.main,
        borderColor: palette.error.main,
        color: palette.error.main,
      },
    };

    //Start stripe elements creations

    const element = stripe.elements({ clientSecret });

    const cardNumber = element.create('cardNumber', {
      style: cardStyle,
    });
    cardNumber.mount(`#${cardNumberId}`);
    if (cardNumber) {
      setCardNumberElement(cardNumber);
    }

    const cardExpiryElement = element.create('cardExpiry', {
      style: cardStyle,
    });
    cardExpiryElement.mount(`#${expirationCardDateId}`);

    const cardCvcElement = element.create('cardCvc', {
      style: cardStyle,
    });
    cardCvcElement.mount(`#${securityCardCodeId}`);
    setElements(element);

    //End stripe elements creations

    //Start stripe elements onFocus handlers

    cardNumber.on('focus', function () {
      onFocus(cardNumberId);
    });

    cardExpiryElement.on('focus', function () {
      onFocus(expirationCardDateId);
    });

    cardCvcElement.on('focus', function () {
      onFocus(securityCardCodeId);
    });

    //End stripe elements onFocus handlers

    //Start stripe elements onChange handlers

    cardNumber.on('change', function (event) {
      checkCardType(event.brand);
      setIsCompleteCardNumber(event.complete);
      let message = event.empty ? cardNumberInvalid : event.error?.message;
      if (isFieldInvalid(message)) {
        message = cardNumberInvalid;
      }
      onError(cardNumberId, message);
    });

    cardExpiryElement.on('change', function (event) {
      setIsCompleteCardExpiry(event.complete);
      let message = event.empty ? cardExpiryInvalid : event.error?.message;
      if (isFieldInvalid(message)) {
        message = cardExpiryInvalid;
      }
      onError(expirationCardDateId, message);
    });

    cardCvcElement.on('change', function (event) {
      setIsCompleteCardCvc(event.complete);
      let message = event.empty ? cardCvcInvalid : event.error?.message;
      if (isFieldInvalid(message)) {
        message = cardCvcInvalid;
      }
      onError(securityCardCodeId, message);
    });
  };

  useLayoutEffect(() => {
    initLayout();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientSecret, stripe]);

  const isFieldInvalid = (message?: string) => {
    return message?.includes('invalid') || message?.includes('incomplete');
  };

  const onFocus = (id = '') => {
    setFieldFocus(() => ({
      [cardNumberId]: id === cardNumberId,
      [expirationCardDateId]: id === expirationCardDateId,
      [securityCardCodeId]: id === securityCardCodeId,
    }));
  };

  const checkCardType = (brand: string) => {
    switch (brand) {
      case 'visa':
        setCardNumberImages([VISA_LOGO]);
        break;

      case 'mastercard':
        setCardNumberImages([MASTERCARD_LOGO]);
        break;

      case 'amex':
        setCardNumberImages([AMEX_LOGO]);
        break;

      default:
        setCardNumberImages([VISA_LOGO, MASTERCARD_LOGO, AMEX_LOGO]);
        break;
    }
  };

  const onError = (id = '', value = '') => {
    setFieldError((prevState) => ({
      ...prevState,
      [id]: value,
    }));
  };

  const handlePaymentStatus = (paymentIntent: PaymentIntent | undefined, error: StripeError | undefined) => {
    if (!paymentIntent) {
      if (error) {
        setMessage(error.message);
        setIsError(error.type !== 'validation_error');
      } else {
        setMessage('Something went wrong.');
      }
    } else {
      switch (paymentIntent.status) {
        case 'succeeded':
          setMessage('Payment succeeded!');
          setIsError(false);
          dispatch(
            setScreen({
              screen: orderSuccessScreen,
              updating: false,
            }),
          );
          return;
        case 'requires_action':
          handleStripeRequiresAction(paymentIntent.next_action);
          return;
        case 'processing':
          setMessage('Your payment is processing.');
          break;
        case 'requires_payment_method':
          initLayout();
          setIsError(true);
          setMessage('Your payment was not successful, please try again.');
          break;
        default:
          setMessage('Something went wrong.');
          break;
      }
    }
  };

  const [secureUrl, setSecureUrl] = useState<string>('');
  const [isLoadingSecureIframe, setIsLoadingSecureIframe] = useState<boolean>(false);
  const handleStripeRequiresAction = (nextAction: PaymentIntent.NextAction | null) => {
    setIsLoadingSecureIframe(true);

    if (nextAction?.redirect_to_url?.url) {
      setSecureUrl(nextAction?.redirect_to_url?.url);
    }
    return;
  };

  const handle3DSComplete = useCallback(
    async (event: MessageEvent) => {
      if (event.data !== STRIPE_3D_SECURE_POST_MESSAGE) return;
      setIsLoadingSecureIframe(true);
      console.log('secure-completed');
      if (!stripe || !clientSecret) return;
      const { paymentIntent, error: confirmError } = await stripe.retrievePaymentIntent(clientSecret);
      console.log('secure-payment-intent:', paymentIntent);
      console.log('secure-confirm-error:', confirmError);

      setSecureUrl('');
      handlePaymentStatus(paymentIntent, confirmError);
      setIsLoadingSecureIframe(false);
      return;
    },
    [stripe, clientSecret],
  );

  useEffect(() => {
    if (stripe) {
      window.removeEventListener('message', handle3DSComplete);
      window.addEventListener('message', handle3DSComplete);
    }
    return () => window.removeEventListener('message', handle3DSComplete);
  }, [stripe, handle3DSComplete]);

  const onClickContinue = async () => {
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    if (!isCompleteCardNumber) {
      onError(cardNumberId, fieldError[cardNumberId] || cardNumberInvalid);
    }

    if (!isCompleteCardExpiry) {
      onError(expirationCardDateId, fieldError[expirationCardDateId] || cardExpiryInvalid);
    }

    if (!isCompleteCardCvc) {
      onError(securityCardCodeId, fieldError[securityCardCodeId] || cardCvcInvalid);
    }

    if (!isComplete) return;

    setIsLoading(true);
    handleCardPayment('').catch((err) => {
      console.log('err:', err);
      const error = {
        clientSecret: '',
        message: err.name === 'FetchError' ? FETCH_ERROR : err.message,
      };
      setIsLoading(false);
      failCallback(error);
    });
  };

  const handleCardPayment = async (secret: string) => {
    if (!cardNumberElement) return;
    if (!stripe) return;
    if (!clientSecret) return;
    console.log('client secret to be used on payment:', clientSecret);
    const returnUrl = window.location.origin + PAYMENT_REDIRECT_PATH;
    const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
      clientSecret,
      {
        return_url: returnUrl,
        payment_method: {
          card: cardNumberElement,
          billing_details: {
            name: sender?.name,
            email: sender?.email,
            phone: sender?.mobile,
          },
        },
      },
      { handleActions: false },
    );
    console.log('paymentIntent:', paymentIntent);
    console.log('confirmError:', confirmError);
    handlePaymentStatus(paymentIntent, confirmError);
    setIsLoading(false);
  };

  const onClickBack = () => {
    dispatch(setPayment({ paymentForm: 'select' }));
  };

  const renderImagesOnInput = (images: Array<string>) => {
    return images.map((step, index) => (
      <img
        key={`image${index}`}
        src={step}
        alt={''}
        width={'31.17px'}
        height={'22px'}
        style={{
          // marginLeft: index === 0 || index === images.length - 1 ? '2px' : '0px',
          marginRight: index === 0 || index === images.length - 1 ? '2px' : '0px',
        }}
      />
    ));
  };

  const getCardParentContainer = (fieldName: string) => {
    const fieldFocusColor = isFieldFocus[fieldName] ? palette.primary.dark : palette.grey[100];
    const borderColor = fieldError[fieldName] ? palette.error.main : fieldFocusColor;
    return styles.cardImageParentContainer(palette, borderColor);
  };

  const renderCardDetails = () => {
    const cardCvcImages = [GROUP_VECTORS_CARD];

    return (
      <Flex style={styles.cardParentContainer}>
        <LabelField label={'Card number'} />
        <Flex style={styles.cardNumberParentContainer}>
          <Flex style={getCardParentContainer(cardNumberId)}>
            <div
              id={cardNumberId}
              style={styles.cardImageContainer(palette)}
              ref={cardNumberRef}
              className={isLoading ? classes.disableField : ''}
            ></div>
            {renderImagesOnInput(cardNumberImages)}
          </Flex>
          <ErrorMessage errorMessage={fieldError[cardNumberId]} />
        </Flex>
        <Spacer height={'8px'} />

        <Flex style={Containers.row}>
          {/* Start Expiration Date Field */}
          <Flex style={styles.cardExpiryParentContainer}>
            <LabelField label={'Expiration date'} />
            <Flex style={getCardParentContainer(expirationCardDateId)}>
              <div
                id={expirationCardDateId}
                style={styles.cardExpiryContainer}
                className={isLoading ? classes.disableField : ''}
              ></div>
            </Flex>
            <ErrorMessage errorMessage={fieldError[expirationCardDateId]} />
          </Flex>
          {/* End Expiration Date Field */}
          {/* Start Security Field */}
          <Flex style={Containers.column}>
            <LabelField label={'Security code'} />
            <Flex style={getCardParentContainer(securityCardCodeId)}>
              <div
                id={securityCardCodeId}
                style={styles.cardImageContainer(palette)}
                className={isLoading ? classes.disableField : ''}
              ></div>
              {renderImagesOnInput(cardCvcImages)}
            </Flex>
            <ErrorMessage errorMessage={fieldError[securityCardCodeId]} />
          </Flex>
          {/* End Security Field */}
        </Flex>
      </Flex>
    );
  };

  const MessageText = () => {
    const stringMsg = message?.length !== 0 ? message : 'Empty';
    return (
      <Flex style={styles.messageContainer(isError)}>
        <p style={styles.errorTextHeaderMessage(theme)}>Payment Failed</p>
        <p style={styles.errorSubTextMessage(theme)}>{stringMsg}</p>
      </Flex>
    );
  };

  const renderCardForm = () => {
    return <Flex style={styles.paymentMethodParentContainer}>{renderCardDetails()}</Flex>;
  };

  const renderPayButton = () => {
    return (
      <ButtonSheet
        withBack
        label={'Pay AUD $' + totalAmount.toFixed(2)}
        isLoading={isLoading}
        disabled={isLoading}
        disabledBack={isLoading}
        onPress={onClickContinue}
        onPressBack={onClickBack}
      />
    );
  };

  const SecureIframeComponent = () => {
    return (
      <Flex style={{ width: '100%', marginTop: -10 }}>
        {isLoadingSecureIframe && (
          <Flex style={{ ...Containers.column, position: 'absolute', top: '50%', left: '47%' }}>
            <Flex style={Containers.rowCenterCenter}>
              <CircularProgress size={'2rem'} style={styles.loading} />
            </Flex>
            <Flex style={Containers.rowCenterCenter}>
              <LabelField label={'Loading!'} />
            </Flex>
          </Flex>
        )}
        <iframe
          height={'400px'}
          width={'100%'}
          title="Card secure authentication!"
          src={secureUrl}
          frameBorder="0"
          scrolling="no"
          onLoad={() =>
            setTimeout(() => {
              setIsLoadingSecureIframe(false);
            }, 500)
          }
        />
      </Flex>
    );
  };

  // display 3D secure iframe if require
  return (
    <>
      {secureUrl ? (
        SecureIframeComponent()
      ) : (
        <Flex style={Containers.column}>
          {renderCardForm()}
          {MessageText()}
          {renderPayButton()}
        </Flex>
      )}
    </>
  );
};

export default CheckoutForm;

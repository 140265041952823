import { FC } from 'react';

import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { SheetLabel } from './types';
import Flex from '../../components/Common/Flex';
import { Containers } from '../../styles/AppClip';

const styles = {
  title: (theme: Theme, labelStyle = {}) => ({
    ...theme.typography.h4,
    fontSize: '28px',
    lineHeight: '34px',
    marginTop: 16,
    marginBottom: 0,
    ...labelStyle,
  }),
  subTitle: (theme: Theme, labelStyle = {}) => ({
    ...theme.typography.h6,
    lineHeight: '22px',
    marginTop: '8px',
    marginBottom: 0,
    ...labelStyle,
  }),
};

const SheetHeaderLabel: FC<SheetLabel> = ({ label, subLabel, labelStyle }) => {
  const theme = useTheme();

  return (
    <Flex
      style={{
        ...Containers.column,
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: 0,
        marginBottom: '24px',
      }}
    >
      <p style={styles.title(theme, labelStyle)}>{label}</p>
      {subLabel ? <p style={styles.subTitle(theme, labelStyle)}>{subLabel}</p> : null}
    </Flex>
  );
};

export default SheetHeaderLabel;

import { Tenant } from '../types';
import { AppConfig } from './config';

const MASTERCARD_TENANT: Tenant = {
  tenantName: 'mastercard',
  configuration: {
    appClip: {
      hideLogo: true,
    },
  },
};

const AUSPOST_TENANT: Tenant = {
  tenantName: 'auspost',
  configuration: {
    appClip: {
      hideLogo: true,
    },
  },
};

export const TENANTS = {
  mastercardgift: MASTERCARD_TENANT,
  auspost: AUSPOST_TENANT,
};

export const STRIPE_3D_SECURE_POST_MESSAGE = '3DS-authentication-complete';

export const PAYMENT_REDIRECT_PATH = '/payment/redirect';

export const GA_EVENTS = {
  SELECT_AMOUNT: AppConfig.GA_EVENTS.SELECT_AMOUNT,
  RECIPIENT_DETAILS: AppConfig.GA_EVENTS.RECIPIENT_DETAILS,
  PAYMENT_CARD: AppConfig.GA_EVENTS.PAYMENT_CARD,
  SUCCESS: AppConfig.GA_EVENTS.SUCCESS,
};

export const ORDER_RECIEPT_RETRY_LIMIT = 20;
export const ORDER_RECEIPT_FAILURE_RETRY_LIMIT = 5;
export const FEES_GST_FAILURE_RETRY_LIMIT = 3;

export const FETCH_ERROR = 'Failed to fetch';
export const NOT_FOUND_ERROR = 'Not found error';

export const DEFAULT_TIMEZONE = 'Australia/Brisbane';
export const STEPS = [1, 2, 3, 4, 5];

export const APP_IMAGES = {
  bannerLg: 'banner-lg',
  bannerXs: 'banner-xs',
  logo: 'logo',
  cardLg: 'card-lg',
  cardXs: 'card-xs',
  spinner: 'spinner',
};

export enum ORDER_STATUS {
  PENDING = 'pending',
  CREATED = 'created',
  FAILED = 'failed',
  COMPLETED = 'completed',
}

import { CircularProgress } from '@material-ui/core';
import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useStripe } from '@stripe/react-stripe-js';
import { CanMakePaymentResult, PaymentRequest, PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import moment from 'moment';
import { ChangeEvent, FC, FocusEvent, useCallback, useEffect, useState } from 'react';
import { isAndroid } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import { postPaymentIntent } from '../../../api';
import {
  AppConfig,
  COUNTRY_CODE,
  FIELD_IDS,
  PAYMENT_CARD_FIELDS,
  Payment as PaymentConstants,
  REGEX_PATTERNS,
  SCREEN_IDS,
} from '../../../constants';
import { useConnectContext } from '../../../contexts/ConnectContext';
import ApplePayLogo from '../../../images/apple-pay-logo.svg';
import {
  RootState,
  paymentActions,
  purchaseActions,
  screenActions,
  useAppDispatch,
  useAppSelector,
} from '../../../store';
import { Containers } from '../../../styles/AppClip';
import {
  IPaymentIntentData,
  IPaymentIntentResponse,
  PAYMENT_METHOD_VALUES,
  SEND_DATE_OPTIONS,
  ValidateInputProps,
} from '../../../types';
import {
  capitalizeFirstLetter,
  checkScheduleDateExpired,
  dollarToCent,
  onChangeEmail,
  onChangeMobileNumber,
  separateFullName,
} from '../../../utils/helpers';
import Flex from '../../Common/Flex';
import { ButtonSheet, TermsCondition, TotalComponent } from '../index';
import { PaymentButtonMethod } from './PaymentMethodButton';
import SenderForm from './SenderForm';

const googlePayIcon = '/google-pay.png';
const applePayIcon = '/apple-pay.png';
const googlePayMethodIcon = '/google-pay-logo.jpeg';

const { WALLET, CARD } = PAYMENT_METHOD_VALUES;
const { header, subheader } = PaymentConstants;

const styles = {
  labelField: (theme: Theme) => ({
    ...theme.typography.caption,
    fontSize: '15px',
    lineHeight: '20px',
    marginTop: '0px',
    marginBottom: '4px',
    textTransform: 'none' as const,
  }),
  subLabelField: (theme: Theme) => ({
    ...theme.typography.h5,
    color: theme.palette.text.primary,
    fontWeight: 300,
    fontSize: '13px',
    marginTop: '0px',
    textTransform: 'none' as const,
  }),
  walletButton: {
    backgroundColor: '#000',
    textTransform: 'none',
    marginTop: 24,
  },
  payButton: {
    marginTop: 24,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
  },
  backButton: {
    marginTop: 24,
  },
  fieldStyle: {
    backgroundColor: '#FFFFFF',
    borderRadius: '10px',
  },
  cardParentContainer: {
    flex: 1,
    flexDirection: 'column' as const,
    marginRight: 8,
    marginLeft: 8,
  },
  paymentMethodParentContainer: (theme: Theme, isSelected: boolean) => ({
    ...Containers.column,
    marginBottom: '12px',
    padding: '18px 12px 18px 14px',
    backgroundColor: theme.palette.grey[100],
    border: `2px solid ${isSelected ? theme.palette.primary.dark : 'transparent'}`,
    borderRadius: '10px',
  }),
  googlePayMethod: {
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '4px',
    paddingTop: 2,
    paddingBottom: 2,
  },
  applePayMethod: {
    backgroundColor: 'white',
    border: '1px solid black',
    borderRadius: '4px',
    paddingTop: 5,
    paddingBottom: 5,
    paddingLeft: 4,
    paddingRight: 4,
    width: 28,
  },
  customButtonText: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.common.white,
    fontSize: '16px',
    fontWeight: 600,
    marginRight: '8px',
    marginTop: 0,
    marginBottom: 0,
  }),
  errorTextHeaderMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '1.1rem',
    fontWeight: 500,
    textAlign: 'center' as const,
    alignSelf: 'center' as const,
    marginTop: 0,
    marginBottom: 0,
    marginRight: 0,
  }),
  errorSubTextMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '0.8rem',
    textAlign: 'center' as const,
    marginTop: '9px',
    marginBottom: 0,
    marginRight: 0,
  }),
  errorMessage: (theme: Theme) => ({
    ...theme.typography.caption,
    color: theme.palette.error.main,
    fontSize: '0.7rem',
    textAlign: 'right' as const,
    marginTop: '0px',
    marginBottom: '4.5px',
    marginRight: 8,
  }),
};

interface ISelectPaymentMethodScreen {
  setHeading: (text: string) => void;
  setSubHeading: (text: string) => void;
  setScreenLoading: (isLoading: boolean) => void;
  failCallback: (response: IPaymentIntentResponse) => void;
}

const SelectPaymentMethodScreen: FC<ISelectPaymentMethodScreen> = ({
  setSubHeading,
  setScreenLoading,
  setHeading,
  failCallback,
}) => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const stripe = useStripe();
  const [paymentRequest, setPaymentRequest] = useState<PaymentRequest | undefined>();
  const [paymentRequestResult, setPaymentRequestResult] = useState<CanMakePaymentResult>();
  const { setUUID4 } = purchaseActions;

  const { selectedAmount, totalAmount, fees, gst } = useAppSelector((state: RootState) => state.purchase);
  const { recipientDetails } = useAppSelector((state: RootState) => state.recipient);
  const { scheduledDelivery } = useAppSelector((state: RootState) => state.schedule);
  const purchase = useAppSelector((state: RootState) => state.purchase);
  const { merchantData } = useAppSelector((state: RootState) => state.merchant);
  const { paymentMethod, sender } = useAppSelector((state: RootState) => state.payment);
  const { setPayment } = paymentActions;
  const { setScreen } = screenActions;

  const isWallet = paymentMethod === WALLET;
  const isCard = paymentMethod === CARD;

  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState<string | undefined>('');

  const { firstCardNameId, lastCardNameId, emailCardId, mobileCardNumberId } = FIELD_IDS.card;

  const [isFieldFocus, setFieldFocus] = useState({
    [firstCardNameId]: false,
    [lastCardNameId]: false,
    [emailCardId]: false,
    [mobileCardNumberId]: false,
  });
  const [fieldValue, setFieldValue] = useState({
    [firstCardNameId]: sender?.firstName || '',
    [lastCardNameId]: sender?.lastName || '',
    [emailCardId]: sender?.email || '',
    [mobileCardNumberId]: sender?.mobile || '',
    mobileNumberDisplay: sender?.mobileNumberDisplay || '',
  });
  const [fieldError, setFieldError] = useState({
    [firstCardNameId]: sender?.firstNameErr,
    [lastCardNameId]: sender?.lastNameErr,
    [emailCardId]: sender?.emailErr,
    [mobileCardNumberId]: sender?.mobileNumberErr,
  });

  const walletName = isAndroid ? 'Google Pay' : 'Apple Pay';
  const { reviewOrderScreen, orderSuccessScreen } = SCREEN_IDS;

  useEffect(() => {
    setHeading(header);
    setSubHeading(subheader);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { toggScheduleExpiredDialog } = useConnectContext();
  const { deliveryOptionDate: scheduleMode } = scheduledDelivery;

  const getPaymentRequest = async () => {
    if (!stripe) {
      console.warn('Stripe.js has not loaded yet.');
      return;
    }
    try {
      const total = dollarToCent(totalAmount);
      const pr = stripe.paymentRequest({
        country: 'AU',
        currency: 'aud',
        total: {
          label: `Pay ${merchantData.merchantName} | Karta`,
          amount: Number(total),
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });

      // Check the availability of the Payment Request API.
      const result = await pr.canMakePayment();
      // TODO remove this log
      console.log('result', result);
      setScreenLoading(false);
      if (result?.applePay || result?.googlePay) {
        setPaymentRequest(pr);
        setPaymentRequestResult(result);
      }
    } catch (error) {
      console.log('err:', error);
      setScreenLoading(false);
      failCallback(error as IPaymentIntentResponse);
    }
  };

  useEffect(() => {
    getPaymentRequest();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe, totalAmount]);

  const callPaymentIntent = useCallback(
    async (appClipTransactionId: string, event?: PaymentRequestPaymentMethodEvent) => {
      const { deliveryDate } = scheduledDelivery;
      let senderDetails = {
        firstName: sender?.firstName || '',
        lastName: sender?.lastName || '',
        name: sender?.name || '',
        email: sender?.email || '',
        mobile: sender?.mobileNumberDisplay || '',
      };
      if (event) {
        senderDetails = getPayerDetails(event);
      }

      const data: IPaymentIntentData = {
        amount: totalAmount,
        appId: AppConfig.APP_ID,
        metadata: {
          subtotal: selectedAmount,
          appClipTransactionId,
          timestamp: Math.floor(Date.now() / 1000),
          qrToken: AppConfig.QRT_CODE,
          recipient: {
            ...recipientDetails,
            mobileNumberDisplay: scheduledDelivery?.mobileNumberDisplay || recipientDetails?.mobileNumberDisplay,
            email: scheduledDelivery?.emailAddress,
          },
          sender: senderDetails,
          deliveryDate: moment(deliveryDate).toISOString(),
          deliveryStatus: scheduledDelivery.deliveryOptionDate,
        },
      };
      const walletPaymentClientSecret = await postPaymentIntent({ data });
      return walletPaymentClientSecret;
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [sender, recipientDetails, totalAmount, scheduledDelivery],
  );

  useEffect(() => {
    if (!paymentRequest || !stripe) {
      return;
    }

    paymentRequest.off('paymentmethod');
    paymentRequest.on('paymentmethod', async (ev) => {
      console.log('paymentmethod event:', ev);

      const appClipTransactionId = uuidv4();
      dispatch(setUUID4(appClipTransactionId));
      const response = await callPaymentIntent(appClipTransactionId, ev);
      const { clientSecret: walletPaymentClientSecret } = response;

      if (!walletPaymentClientSecret) {
        failCallback(response);
        ev.complete('fail');
        return;
      }

      const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(
        walletPaymentClientSecret,
        { payment_method: ev.paymentMethod.id },
        { handleActions: false },
      );
      console.log('paymentIntent:', paymentIntent);
      console.log('confirmError:', confirmError);

      if (confirmError) {
        setMessage(confirmError.message);
        ev.complete('fail');
        paymentRequest.abort();
      } else {
        ev.complete('success');
        if (paymentIntent.status === 'requires_action') {
          const { error } = await stripe.confirmCardPayment(walletPaymentClientSecret);
          if (error) {
            setMessage(error.message);
            paymentRequest.abort();
          } else {
            handleSuccessPayment(ev);
          }
        } else {
          handleSuccessPayment(ev);
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paymentRequest, stripe, callPaymentIntent]);

  const getPayerDetails = (ev: PaymentRequestPaymentMethodEvent) => {
    const { payerName = '', payerPhone = '', payerEmail = '' } = ev;
    const { firstName = '', lastName = '' } = separateFullName(payerName);
    return {
      firstName,
      lastName,
      name: payerName,
      mobile: payerPhone,
      email: payerEmail,
    };
  };

  const savePayerDetails = (ev: PaymentRequestPaymentMethodEvent) => {
    const senderDetails = getPayerDetails(ev);
    dispatch(setPayment({ sender: senderDetails }));
  };

  const handleSuccessPayment = (ev: PaymentRequestPaymentMethodEvent) => {
    savePayerDetails(ev);
    dispatch(
      setScreen({
        screen: orderSuccessScreen,
        updating: false,
      }),
    );
  };

  const setPaymentMethod = (paymentMethod: string) => {
    dispatch(setPayment({ paymentMethod }));
  };

  const onPressWalletMethod = () => {
    if (paymentMethod === WALLET) {
      return;
    }
    setPaymentMethod(WALLET);
    setMessage('');
    PAYMENT_CARD_FIELDS.forEach((paymentField) => {
      const { id } = paymentField;
      validateInputField({ id, payment: WALLET, regexStr: paymentField.pattern });
    });
  };

  const onPressCardMethod = () => {
    if (paymentMethod === CARD) {
      return;
    }
    setPaymentMethod(CARD);
    setMessage('');
    PAYMENT_CARD_FIELDS.forEach((paymentField) => {
      const { id } = paymentField;
      validateInputField({ id, payment: CARD, regexStr: paymentField.pattern });
    });
  };

  const onFocus = (event: FocusEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    setFieldFocus((prevState) => ({
      ...prevState,
      [id]: true,
    }));
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const regexStr = REGEX_PATTERNS.mobileNumberShort;
    if (id === mobileCardNumberId) {
      setAndValidateInputField({ regexStr, id, value, shouldFormatNumber: true });
    }
    setFieldFocus((prevState) => ({
      ...prevState,
      [id]: false,
    }));
  };

  const getFieldName = (id: string, isValidationError?: boolean) => {
    switch (id) {
      case firstCardNameId:
      case lastCardNameId:
        return id.replaceAll('-', ' ');

      case mobileCardNumberId:
        return isValidationError ? 'Australian mobile number' : 'mobile phone number';

      default:
        return id.replaceAll('-', ' ');
    }
  };

  const setAndValidateInputField = (params: ValidateInputProps) => {
    const { id, regexStr, shouldFormatNumber = false } = params;
    let value = params.value;

    if (id === mobileCardNumberId) {
      let numberToDisplayFormat = '';
      const formattedNumber = onChangeMobileNumber(value);
      value = formattedNumber.mobileNumberId;
      if (shouldFormatNumber) {
        numberToDisplayFormat = `${COUNTRY_CODE} ${formattedNumber.mobileNumberDisplay}`;
      } else {
        numberToDisplayFormat = `${COUNTRY_CODE} ${formattedNumber.mobileNumberId}`;
      }
      setFieldValue((prevState) => ({
        ...prevState,
        mobileNumberDisplay: numberToDisplayFormat,
      }));
    }

    setFieldValue((prevState) => ({
      ...prevState,
      [id]: value,
    }));
    validateInputField({ id, inputValue: value, regexStr });
  };

  const validateInputField = ({
    id,
    inputValue,
    regexStr,
    payment,
  }: {
    id: string;
    inputValue?: string;
    regexStr?: string | RegExp;
    payment?: PAYMENT_METHOD_VALUES;
  }) => {
    const value = inputValue || fieldValue[id];
    const fieldName = getFieldName(id);
    let error = '';
    if ((payment || paymentMethod) !== WALLET) {
      if (!value.length) {
        error = `Please provide ${fieldName}.`;
      } else if (value.length < 2) {
        error = `Please enter a valid ${fieldName}.`;
      } else if (regexStr) {
        const regex = new RegExp(regexStr);
        const isValidInput = regex.test(value.toLowerCase().trim());
        if (!isValidInput) {
          error = `Please enter a valid ${getFieldName(id)}.`;
        }
      }
    }
    setFieldError((prevState) => ({
      ...prevState,
      [id]: error,
    }));
    return error;
  };

  useEffect(() => {
    const senderDetails = {
      name: `${fieldValue[firstCardNameId]} ${fieldValue[lastCardNameId]}`,
      email: fieldValue[emailCardId],
      mobile: fieldValue[mobileCardNumberId],
      mobileNumberDisplay: fieldValue.mobileNumberDisplay,
      firstName: fieldValue[firstCardNameId],
      lastName: fieldValue[lastCardNameId],
      firstNameErr: fieldError[firstCardNameId],
      lastNameErr: fieldError[lastCardNameId],
      emailErr: fieldError[emailCardId],
      mobileNumberErr: fieldError[mobileCardNumberId],
    };
    dispatch(setPayment({ sender: senderDetails }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, setPayment, fieldValue, fieldError]);

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { id } = event.currentTarget;
    const originalValue = event.currentTarget.value;
    let value = originalValue.replace(/[^a-z .'-]/gi, '');
    const regexStr = PAYMENT_CARD_FIELDS.find((field) => field.id === id)?.pattern;

    switch (id) {
      case firstCardNameId:
        value = capitalizeFirstLetter(value);
        break;
      case lastCardNameId:
        value = capitalizeFirstLetter(value);
        break;

      case emailCardId:
        value = onChangeEmail(originalValue);
        break;

      case mobileCardNumberId:
        value = originalValue;
        break;

      default:
        break;
    }
    setAndValidateInputField({ regexStr, id, value });
  };

  const onClickContinue = async () => {
    if (scheduleMode !== SEND_DATE_OPTIONS.NOW && scheduledDelivery.deliveryDate) {
      const scheduleDate =
        typeof scheduledDelivery.deliveryDate === 'string'
          ? new Date(scheduledDelivery.deliveryDate)
          : scheduledDelivery.deliveryDate;
      const isScheduleExpire = checkScheduleDateExpired(scheduleDate);

      if (isScheduleExpire) {
        toggScheduleExpiredDialog(true);
        return;
      }
    }

    if (!stripe) {
      return;
    }
    setIsLoading(true);
    if (isWallet) {
      if (!paymentRequest) {
        setMessage(`Sorry! ${walletName} is not available on your device.`);
      } else {
        paymentRequest.show();
      }
      return;
    }

    const errors = PAYMENT_CARD_FIELDS.map((paymentField) => {
      const { id } = paymentField;
      return validateInputField({ id, regexStr: paymentField.pattern });
    });

    if (Object.values(fieldValue).some((value) => !value) || Object.values(errors).some((value) => value)) {
      setIsLoading(false);
      return;
    }
    const appClipTransactionId = uuidv4();

    const response = await callPaymentIntent(appClipTransactionId);
    const { clientSecret: cardPaymentClientSecret } = response;
    if (!cardPaymentClientSecret) {
      failCallback(response);
      return;
    }
    setIsLoading(false);
    dispatch(setUUID4(appClipTransactionId));
    dispatch(setPayment({ paymentForm: paymentMethod, clientSecret: cardPaymentClientSecret }));
  };

  const onClickBack = () => {
    dispatch(
      setScreen({
        screen: reviewOrderScreen,
        loading: false,
        updating: false,
      }),
    );
  };

  const getFieldValue = (id: string) => {
    return id !== mobileCardNumberId ? fieldValue[id] : fieldValue.mobileNumberDisplay;
  };

  const renderPaymentMethods = () => {
    return (
      <>
        <Flex style={styles.paymentMethodParentContainer(theme, true)}>
          <SenderForm
            paymentCardFields={PAYMENT_CARD_FIELDS}
            onFocus={onFocus}
            onBlur={onBlur}
            onInput={onInput}
            isFieldFocus={isFieldFocus}
            getFieldValue={getFieldValue}
            fieldError={fieldError}
            isLoading={isLoading}
            theme={theme}
            data-testid="sender-form"
          />
        </Flex>
        <PaymentButtonMethod
          label={'Debit / Credit Card'}
          selected={isCard}
          expand={false}
          isCreditCard={true}
          onPress={onPressCardMethod}
        />
        {paymentRequestResult?.googlePay && (
          <PaymentButtonMethod
            imageLabel={googlePayMethodIcon}
            label={'Google Pay'}
            selected={isWallet}
            expand={false}
            onPress={onPressWalletMethod}
            customImageStyle={styles.googlePayMethod}
          />
        )}
        {paymentRequestResult?.applePay && (
          <PaymentButtonMethod
            imageLabel={ApplePayLogo}
            label={'Apple Pay'}
            selected={isWallet}
            expand={false}
            onPress={onPressWalletMethod}
            customImageStyle={styles.applePayMethod}
          />
        )}
      </>
    );
  };

  const renderButtonLabel = () => {
    const walletIcon = paymentRequestResult?.googlePay ? googlePayIcon : applePayIcon;

    return (
      <Flex style={Containers.rowCenterCenter}>
        <label style={styles.customButtonText(theme)}>Pay with</label>
        <img src={walletIcon} height={20} alt={''} />
      </Flex>
    );
  };

  const renderMessage = () => {
    return (
      <Flex style={{ flexDirection: 'column', flex: 1, opacity: message?.length !== 0 ? 1 : 0 }}>
        <p style={styles.errorTextHeaderMessage(theme)}>Payment Failed</p>
        <p style={styles.errorSubTextMessage(theme)}>{message}</p>
      </Flex>
    );
  };

  const renderPayButton = () => {
    // check if object fieldError has any value
    const isAllFieldsValid =
      Boolean(sender?.firstName && sender?.lastName && sender?.email && sender.mobile) &&
      !Object.values(fieldError).some((value) => value);
    if (isWallet) {
      return (
        <ButtonSheet
          withBack
          onPress={onClickContinue}
          onPressBack={onClickBack}
          customLabel={renderButtonLabel()}
          buttonStyle={styles.backButton}
          backButtonStyle={styles.backButton}
          isLoading={isLoading}
          disabled={false}
        />
      );
    }
    return (
      <ButtonSheet
        withBack
        label={'Pay AUD $' + totalAmount.toFixed(2)}
        isLoading={isLoading || purchase.loading}
        disabled={isLoading || !isAllFieldsValid}
        disabledBack={isLoading}
        onPress={onClickContinue}
        onPressBack={onClickBack}
        buttonStyle={styles.backButton}
        backButtonStyle={styles.backButton}
      />
    );
  };

  if (!totalAmount) return null;

  return (
    <Flex
      style={{
        flexDirection: 'column',
      }}
    >
      {renderPaymentMethods()}
      {!!message && renderMessage()}
      <TermsCondition />
      {purchase.loading ? (
        <CircularProgress
          size={'1.5rem'}
          style={{
            color: 'black',
            marginRight: '8px',
            alignSelf: 'center',
            margin: '20px',
          }}
        />
      ) : (
        <TotalComponent
          totalAmount={`${totalAmount.toFixed(2)}`}
          fee={`${fees?.toFixed(2)}`}
          gst={`${gst?.toFixed(2)}`}
          subTotal={`${selectedAmount.toFixed(2)}`}
        />
      )}

      {renderPayButton()}
    </Flex>
  );
};

export default SelectPaymentMethodScreen;

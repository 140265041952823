import { PaymentRequestPaymentMethodEvent } from '@stripe/stripe-js';
import { AppConfig } from '../../constants';
import { IPaymentIntentData } from '../../types';

const { API, AWSBRAND } = AppConfig;
const { CARD_BASE_URL, STRIPE_PAYMENT_INTENT } = API;

interface IPaymentIntent {
  data: IPaymentIntentData;
  baseUrl?: string;
  path?: string;
  paymentMethodEvent?: PaymentRequestPaymentMethodEvent;
}

export const postPaymentIntent = ({ data, baseUrl = CARD_BASE_URL, path = STRIPE_PAYMENT_INTENT }: IPaymentIntent) => {
  const { metadata, appId } = data;
  const { recipient, sender } = metadata;
  const params = {
    currency: 'aud',
    amount: data.amount,
    appId,
    metadata: {
      subtotal: metadata.subtotal,
      appClipTransactionId: metadata.appClipTransactionId,
      qrt: metadata.qrToken,
      forMe: true,
      delivery: metadata?.delivery || 'SMS',
      timestamp: metadata.timestamp,
      brand: AWSBRAND,
      giftWrapping: metadata?.giftWrapping,
      celebrations: metadata?.celebrations,
      marketingOptIn: metadata?.marketingOptIn,
      deliveryDateTime: metadata?.deliveryDate,
      deliveryStatus: metadata?.deliveryStatus,
      sendNow: metadata?.sendNow,
      recipient: {
        firstName: recipient.firstName,
        lastName: recipient.lastName,
        mobile: recipient?.mobileNumberDisplay?.replace(/\s+/g, ''),
        email: recipient?.email || '',
      },
      message: recipient.giftMessage,
      sender: {
        name: `${sender?.firstName} ${sender?.lastName}`,
        mobile: sender?.mobile?.replace(/\s+/g, ''),
        email: sender?.email,
        firstName: sender?.firstName,
        lastName: sender?.lastName,
      },
    },
  };
  const url = `${baseUrl}/${path}`;
  return fetch(url, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(params),
  })
    .then((res) => {
      if (res.ok) {
        return res.json();
      }
      return res.text().then((text) => {
        throw Error(text);
      });
    })
    .then((data) => {
      return {
        clientSecret: data?.clientSecret,
        message: '',
      };
    })
    .catch((error) => {
      console.log('error message:', error?.message);
      return {
        clientSecret: '',
        message: error?.message,
      };
    });
};

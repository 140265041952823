import { ORDER_STATUS, SCREEN_IDS } from '../constants';
import { CSSProperties } from 'react';

export enum SEND_DATE_OPTIONS {
  NOW = 'now',
  SCHEDULED = 'scheduled',
}

export enum SEND_OPTIONS {
  EMAIL = 'email',
  SMS = 'sms',
}

export interface IScreenProps {
  screen?: SCREEN_IDS;
  loading?: boolean;
  updating?: boolean;
  isCreditCard?: number;
  isExpansion?: boolean;
  fieldsIsFocus?: {
    recipientDetails?: {
      fields: Record<string, boolean>;
    };
    CardDetails?: {
      fields: Record<string, boolean>;
    };
  };
}

export interface IMerchantData {
  merchantLogoDark?: string;
  merchantLogoDarkError?: boolean;
  merchantLogoLight?: string;
  merchantLogoLightError?: boolean;
  merchantBackground?: string;
  merchantBackgroundError?: boolean;
  merchantCardArtWork?: string;
  merchantNameUppercase?: string | undefined; // to be used as default logo if image is not available
  merchantName?: string | undefined;
}

export interface AppClipData {
  merchantImages: IMerchantData;
}

export type IRecipientDetails = Customer & {
  mobileNumberDisplay: string;
  giftMessage: string;
  firstNameERR?: string;
  lastNameERR?: string;
  mobileNumberERR?: string;
  giftMessageERR?: string;
  giftMessageCharCounter?: number;
};

export interface IScheduledDelivery {
  deliveryOptionType?: SEND_OPTIONS;
  deliveryOptionDate?: SEND_DATE_OPTIONS;
  selectedTimeZoneId?: number;
  deliveryDate?: string;
  mobileNumber?: string;
  emailAddress?: string;
  mobileNumberDisplay?: string;
}

export type CardPurchaseStatus = 'pending' | 'success' | 'failure';

export interface CardPurchaseTransaction {
  cardId: string;
  createCard: string;
  giftMessage: string;
  isSmsSent: string;
  orderId: string;
  orderNumber?: string;
  paymentDetails: {
    card: { last4: string };
    type: string;
  };
  pk: string;
  recipientMobile: string;
  sendEmail: string;
  sendSMS: string;
  transactionId: string;
  metadata: IPaymentIntentDataMetadata;
  orderCreated: CardPurchaseStatus;
  sender?: Customer & { name: string };
  recipient?: IRecipientDetails;
  orderStatus: ORDER_STATUS;
}

export interface ILabels {
  label?: string;
  value?: string;
  containerStyle?: CSSProperties;
}

export interface ISenderDetails {
  firstCardName: string;
  lastCardName: string;
  emailCard: string;
  mobileCardNumber: string;
  mobileCardNumberDisplay: string;
  cardNumber: string;
  expirationCardDate: string;
  securityCardCode: string;
  firstCardNameERR: string;
  lastCardNameERR: string;
  emailCardERR: string;
  mobileCardNumberERR: string;
  cardNumberERR: string;
  expirationCardDateERR: string;
  securityCardCodeIdERR: string;
}

export interface ValidateInputProps {
  regexStr?: RegExp | string;
  id: string;
  value: string;
  counter?: number;
  shouldFormatNumber?: boolean;
}

export type Tenant = {
  tenantName: string;
  configuration: {
    appClip?: {
      hideLogo?: boolean;
    };
  };
};

export type ScheduledDelivery = {
  date: Date | null;
};

export interface IPaymentIntentResponse {
  clientSecret?: string;
  message?: string;
  data?: { orderNumber: string };
}

export interface IPaymentIntentData {
  amount: number;
  metadata: IPaymentIntentDataMetadata;
  appId: string;
}

export type Customer = {
  firstName?: string;
  lastName: string;
  email?: string;
  mobile?: string;
};

export type IPaymentIntentDataMetadata = {
  subtotal: number;
  appClipTransactionId: string;
  qrToken: string;
  timestamp: number;
  delivery?: 'sms' | 'email';
  giftWrapping?: boolean;
  celebrations?: string;
  marketingOptIn?: boolean;
  deliveryDate?: string;
  deliveryStatus?: 'now' | 'scheduled';
  sendNow?: boolean;
  recipient: IRecipientDetails;
  sender?: Customer & { name: string };
};

export enum PAYMENT_METHOD_VALUES {
  WALLET = 'wallet',
  CARD = 'card',
}

export type FeesGstResponse = {
  feesValue?: number;
  gstValue?: number;
};

import { ITheme } from '@material-ui/core';
import { Box, styled } from '@mui/material';
import { addYears, isBefore, isValid } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';
import { ButtonSheet, SheetHeaderLabel } from '../../components/AppClip';
import DatePicker from '../../components/AppClip/DatePicker';
import TimePicker from '../../components/AppClip/TimePicker';
import Flex from '../../components/Common/Flex';
import { SCREEN_IDS, ScheduleDelivery as ScheduleDeliveryConstants, DEFAULT_TIMEZONE } from '../../constants';
import { useConnectContext } from '../../contexts/ConnectContext';
import { RootState, scheduledDeliveryActions, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { SEND_DATE_OPTIONS } from '../../types';
import { checkScheduleDateExpired, getDateByTimezone } from '../../utils/helpers';
import moment from 'moment-timezone';

const { header, subheader } = ScheduleDeliveryConstants;
const { setScreen } = screenActions;
const { setScheduledDelivery } = scheduledDeliveryActions;
const { recipientsDetailScreen, reviewOrderScreen } = SCREEN_IDS;

type DateTimeError = {
  type?: 'empty' | 'invalid' | 'expired';
  fields?: ('date' | 'time')[];
};

const getErrorMessage = (field: 'date' | 'time', error: DateTimeError) => {
  if (!error.type) return undefined;
  switch (error.type) {
    case 'expired':
      return error.fields?.find((errorField) => errorField === field) ? `Please select a ${field} in the future` : '';
    default:
      return `Please select a ${field}`;
  }
};

const ScheduleSegment = styled(Flex)(({ theme }) => ({
  width: '100%',
  alignItems: 'center',
  flexBasis: 1,
  borderRadius: 8,
  borderWidth: 2,
  borderStyle: 'solid',
  borderColor: 'rgba(114, 130, 145, 0.25)',
  padding: 1,
}));

const ScheduleButton = styled(Flex)(({ selected }: { theme?: ITheme; selected?: boolean }) => ({
  width: '50%',
  height: 44,
  alignItems: 'center',
  justifyContent: 'center',
  borderRadius: 8,
  fontSize: 17,
  lineHeight: '22px',
  backgroundColor: selected ? '#2E2E2E' : 'transparent',
  color: selected ? '#FFFFFF' : '#2E2E2E',
  cursor: 'pointer',
  letterSpacing: 'normal',
}));

const ScheduleDelivery = () => {
  const { scheduledDelivery } = useAppSelector((state) => state.schedule);
  const { deliveryOptionDate: scheduleMode } = scheduledDelivery;
  const [date, setDate] = useState<Date | null>();
  const { updating } = useAppSelector((state: RootState) => state.screen.screenStates);
  const [dateFieldState, setDateFieldState] = useState<{ date: boolean; time: boolean }>({
    date: false,
    time: false,
  });
  const dispatch = useAppDispatch();

  const { toggScheduleExpiredDialog } = useConnectContext();

  const handleContinueClick = () => {
    if (scheduleMode !== SEND_DATE_OPTIONS.NOW && !!date) {
      const isScheduleExpire = checkScheduleDateExpired(getDateByTimezone(date as Date) ?? '');

      if (isScheduleExpire) {
        toggScheduleExpiredDialog(true);
        return;
      }
    }

    if (scheduleMode === SEND_DATE_OPTIONS.SCHEDULED && !date) {
      return;
    }
    dispatch(setScreen({ screen: reviewOrderScreen, loading: false, updating: false }));
    dispatch(
      setScheduledDelivery({
        deliveryDate: getDateByTimezone(date as Date)?.toISOString(),
        deliveryOptionDate: scheduleMode,
      }),
    );
  };

  const handleBackClick = () => {
    dispatch(
      setScheduledDelivery({
        deliveryDate: getDateByTimezone(date as Date)?.toISOString(),
        deliveryOptionDate: scheduleMode,
      }),
    );
    dispatch(
      setScreen({
        screen: recipientsDetailScreen,
        loading: false,
        updating: false,
      }),
    );
  };

  const handleScheduleModeClick = (mode: SEND_DATE_OPTIONS) => {
    if (mode === SEND_DATE_OPTIONS.NOW) {
      setDateFieldState({ date: false, time: false });
      dispatch(setScheduledDelivery({ deliveryDate: undefined, deliveryOptionDate: mode }));
    } else {
      dispatch(
        setScheduledDelivery({
          deliveryDate: getDateByTimezone(date as Date)?.toISOString(),
          deliveryOptionDate: mode,
        }),
      );
    }
  };

  useEffect(() => {
    if (!scheduledDelivery) return;
    let existingDate = null;
    if (scheduledDelivery.deliveryDate) {
      existingDate = new Date(
        moment.tz(scheduledDelivery.deliveryDate, DEFAULT_TIMEZONE).format('YYYY-MM-DD HH:mm:ss'),
      );
    }
    if (!existingDate) return;
    if (!isValid(existingDate)) return;
    setDate(existingDate);
  }, [scheduledDelivery]);

  const dateTimeError: DateTimeError = useMemo(() => {
    if (scheduleMode === SEND_DATE_OPTIONS.NOW) {
      return {};
    }
    if (scheduleMode === SEND_DATE_OPTIONS.SCHEDULED && !date) {
      return {
        type: 'empty',
        fields: ['date', 'time'],
      };
    }
    if (!date || !isValid(date)) {
      return {
        type: 'invalid',
        fields: ['date', 'time'],
      };
    }
    const aestTime = getDateByTimezone(date);
    if (!aestTime || !isValid(aestTime)) {
      return {
        type: 'invalid',
        fields: ['date', 'time'],
      };
    }
    const now = new Date();
    if (isBefore(aestTime, now)) {
      // check if current time is between 23:55 and 00:00, if yes, date time error
      const targetYear = aestTime.getFullYear();
      const targetMonth = aestTime.getMonth();
      const targetDate = aestTime.getDate();

      const nowYear = now.getFullYear();
      const nowMonth = now.getMonth();
      const nowDate = now.getDate();
      const nowHour = now.getHours();
      const nowMinute = now.getMinutes();
      if (
        targetYear === nowYear &&
        targetMonth === nowMonth &&
        targetDate === nowDate &&
        !(nowHour === 23 && nowMinute >= 55)
      ) {
        return {
          type: 'expired',
          fields: ['time'],
        };
      }
      return {
        type: 'expired',
        fields: ['date', 'time'],
      };
    }
    return {};
  }, [scheduleMode, date]);

  return (
    <Flex style={{ flexDirection: 'column' }}>
      <SheetHeaderLabel label={header} subLabel={subheader} />
      <ScheduleSegment>
        <ScheduleButton
          selected={scheduleMode === SEND_DATE_OPTIONS.NOW}
          onClick={() => handleScheduleModeClick(SEND_DATE_OPTIONS.NOW)}
          sx={{
            fontFamily: 'Rubik',
          }}
        >
          Now
        </ScheduleButton>
        <ScheduleButton
          selected={scheduleMode === SEND_DATE_OPTIONS.SCHEDULED}
          onClick={() => handleScheduleModeClick(SEND_DATE_OPTIONS.SCHEDULED)}
          sx={{
            fontFamily: 'Rubik',
          }}
        >
          Schedule
        </ScheduleButton>
      </ScheduleSegment>
      {scheduleMode === 'scheduled' && (
        <Box my={3}>
          <Box mb={1.5}>
            <DatePicker
              value={date}
              isOpen={dateFieldState.date}
              onChange={(d) => {
                setDate(d as Date);
                setDateFieldState({ date: false, time: false });
              }}
              changeOpen={(state) => setDateFieldState({ time: false, date: state })}
              label="Select date"
              min={new Date()}
              max={addYears(new Date(), 1)}
              error={getErrorMessage('date', dateTimeError)}
            />
          </Box>
          <TimePicker
            value={date}
            isOpen={dateFieldState.time}
            onChange={(d: Date | null) => {
              setDate(d);
              setDateFieldState({ date: false, time: false });
            }}
            onCancel={() => {
              setDate(date);
              setDateFieldState({ date: false, time: false });
            }}
            changeOpen={(state) => setDateFieldState({ date: false, time: state })}
            label="Select time"
            description="Delivery time will be in AEST time zone"
            error={getErrorMessage('time', dateTimeError)}
          />
        </Box>
      )}
      <ButtonSheet
        label={updating ? 'Update' : 'Continue'}
        withBack={!updating}
        onPress={handleContinueClick}
        onPressBack={handleBackClick}
        disabled={!!dateTimeError.type || dateFieldState.date || dateFieldState.time}
        backButtonStyle={{ marginTop: 24 }}
        buttonStyle={{ marginTop: 24 }}
      />
    </Flex>
  );
};

export default ScheduleDelivery;

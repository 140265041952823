import { BoxProps, Button, makeStyles } from '@material-ui/core';
import { Box } from '@mui/material';
import moment from 'moment-timezone';
import { FC } from 'react';
import { ButtonSheet, SheetHeaderLabel, StepBar } from '../../components/AppClip';
import Flex from '../../components/Common/Flex';
import {
  BUTTON_IDS,
  DEFAULT_TIMEZONE,
  FIELD_IDS,
  ReviewOrder as ReviewOrderConstants,
  SCREEN_IDS,
} from '../../constants';
import { useConnectContext } from '../../contexts/ConnectContext';
import InfoCircle from '../../images/info-circle.svg';
import ChevronRight from '../../images/chevron-right.svg';
import CommentText from '../../images/comment-text.svg';
import DollarCircle from '../../images/dollar-circle.svg';
import CalendarSchedule from '../../images/schedule.svg';
import Smartphone from '../../images/smartphone.svg';
import User from '../../images/user.svg';
import { RootState, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { Containers, Typography } from '../../styles/AppClip';
import { SEND_DATE_OPTIONS } from '../../types';
import { checkScheduleDateExpired } from '../../utils/helpers';

const { recipientsDetailScreen, paymentCardScreen, selectAmountScreen, scheduleDeliveryScreen } = SCREEN_IDS;
const { selectAmountBTN, nameBTN, mobileNumberBTN, giftMessageBTN, scheduleDeliveryBTN } = BUTTON_IDS.reviewOrder;
const { firstNameId, lastNameId, mobileNumberId, giftMessageId } = FIELD_IDS.recipient;
const { setScreen } = screenActions;
const { header, subheader } = ReviewOrderConstants;

const useStyles = makeStyles((theme) => ({
  title: {
    ...Typography.rubik.regular,
    color: '#61657B',
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: '7.7px',
    marginBottom: '1.3px',
    textTransform: 'none',
  },
  subTitle: {
    ...Typography.rubik.regular,
    fontSize: '17px',
    lineHeight: '22px',
    marginTop: '4px',
    marginBottom: '7px',
    alignItems: 'start',
    textAlign: 'start',
    textTransform: 'none',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
  },
  titleSpecial: {
    ...Typography.rubik.regular,
    color: '#728291',
    fontSize: '14px',
    lineHeight: '18px',
    fontWeight: 700,
    textTransform: 'none',
    margin: '0px',
    marginBottom: '6px',
  },
  subTitleSpecial: {
    ...Typography.rubik.regular,
    color: '#000000',
    fontSize: '16px',
    lineHeight: '20px',
    fontWeight: 700,
    alignItems: 'start',
    textAlign: 'start',
    textTransform: 'none',
    wordBreak: 'break-all',
    whiteSpace: 'normal',
    margin: '0px',
  },
  btnItem: {
    flex: 1,
    flexDirection: 'row',
    padding: '0px 12px 0px 16px',
    borderRadius: '10px',
    backgroundColor: '#F7FAFF',
  },
  btnItemSpecial: {
    flex: 1,
    flexDirection: 'row',
    padding: 0,
    paddingLeft: '36px',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

interface ButtonContainer {
  title?: string;
  subTitle?: string;
  iconAlpha?: string;
  iconBeta?: string;
  isAestTimeZone?: boolean;
  onClick?: () => void;
}

const ButtonParentContainer: FC<ButtonContainer & BoxProps> = ({
  title,
  subTitle,
  iconAlpha,
  iconBeta,
  isAestTimeZone,
  onClick,
  ...rest
}: ButtonContainer & BoxProps) => {
  const classes = useStyles();
  return (
    <Flex mb={1.5} {...rest}>
      <Button onClick={onClick} className={classes.btnItem}>
        <Flex alignSelf="flex-start" mt={2}>
          <img src={iconAlpha} alt={title} />
        </Flex>
        <Flex flex={1} flexDirection="column" alignItems="start" ml={2} mr={2}>
          <p className={classes.title}>{title}</p>
          <Flex flexDirection="column" maxHeight={150}>
            <p className={classes.subTitle}>{subTitle}</p>
            {isAestTimeZone && <p className={classes.subTitle}>(AEST time zone)</p>}
          </Flex>
        </Flex>
        <Flex alignSelf="flex-start" mt={2.75}>
          <img src={iconBeta} alt="arrow-right" />
        </Flex>
      </Button>
    </Flex>
  );
};

const ReviewOrderScreen: FC = () => {
  const { recipientDetails } = useAppSelector((state: RootState) => state.recipient);
  const { scheduledDelivery } = useAppSelector((state: RootState) => state.schedule);
  const { selectedAmount, fees } = useAppSelector((state: RootState) => state.purchase);
  const { firstName, lastName, mobileNumberDisplay, giftMessage } = recipientDetails;
  const { updating } = useAppSelector((state: RootState) => state.screen.screenStates);

  const dispatch = useAppDispatch();

  const { toggScheduleExpiredDialog } = useConnectContext();
  const { deliveryOptionDate: scheduleMode } = scheduledDelivery;

  const dispatchEvent = (isContinue: boolean) => {
    if (scheduleMode !== SEND_DATE_OPTIONS.NOW) {
      const scheduleDate =
        typeof scheduledDelivery.deliveryDate === 'string'
          ? new Date(scheduledDelivery.deliveryDate)
          : scheduledDelivery.deliveryDate;
      const isScheduleExpire = checkScheduleDateExpired(scheduleDate);

      if (isScheduleExpire) {
        toggScheduleExpiredDialog(true);
        return;
      }
    }

    dispatch(
      setScreen({
        screen: isContinue ? paymentCardScreen : scheduleDeliveryScreen,
        loading: false,
        updating: false,
        fieldsIsFocus: {
          recipientDetails: {
            fields: {
              [firstNameId]: false,
              [lastNameId]: false,
              [mobileNumberId]: false,
              [giftMessageId]: false,
            },
          },
        },
      }),
    );
  };

  const onClickContinue = () => dispatchEvent(true);

  const onClickBack = () => dispatchEvent(false);

  const ClickEvent = (id: string) => {
    const screenDestination = () => {
      switch (id) {
        case selectAmountBTN:
          return selectAmountScreen;
        case scheduleDeliveryBTN:
          return scheduleDeliveryScreen;
        default:
          return recipientsDetailScreen;
      }
    };

    dispatch(
      setScreen({
        screen: screenDestination(),
        loading: false,
        updating: true,
        fieldsIsFocus: {
          recipientDetails: {
            fields: {
              [firstNameId]: id === nameBTN,
              [lastNameId]: false,
              [mobileNumberId]: id === mobileNumberBTN,
              [giftMessageId]: id === giftMessageBTN,
            },
          },
        },
      }),
    );
  };

  const renderItems = () => {
    const ItemContainer = ButtonParentContainer;
    const iconAlphaValue = DollarCircle;
    const iconAlphaTo = User;
    const iconAlphaMobileNumber = Smartphone;
    const iconAlphaGiftMessage = CommentText;
    const iconBeta = ChevronRight;

    const deliveryDate = `${moment
      .tz(scheduledDelivery.deliveryDate, DEFAULT_TIMEZONE)
      .format('DD MMM YYYY')} at ${moment.tz(scheduledDelivery.deliveryDate, DEFAULT_TIMEZONE).format('hh:mm A')}`;

    const isNow = scheduledDelivery.deliveryOptionDate === SEND_DATE_OPTIONS.NOW;

    const subTitle = isNow ? 'Now' : scheduledDelivery.deliveryDate ? deliveryDate : 'N/A';

    return (
      <Flex flexDirection="column">
        {Boolean(fees) && (
          <Box style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
            <img src={InfoCircle} alt="Info Fee" style={{ marginRight: '5px', marginBottom: '2px' }} />
            <p style={{ fontSize: '12px', margin: '0' }}>A purchase fee of ${fees} applies</p>
          </Box>
        )}

        <ItemContainer
          title={'Value'}
          subTitle={`AUD $${selectedAmount}`}
          iconAlpha={iconAlphaValue}
          iconBeta={iconBeta}
          onClick={() => ClickEvent(selectAmountBTN)}
        />
        <ItemContainer
          title={'To'}
          subTitle={`${firstName} ${lastName}`}
          iconAlpha={iconAlphaTo}
          iconBeta={iconBeta}
          onClick={() => ClickEvent(nameBTN)}
        />
        <ItemContainer
          title={'Mobile Number'}
          subTitle={mobileNumberDisplay}
          iconAlpha={iconAlphaMobileNumber}
          iconBeta={iconBeta}
          onClick={() => ClickEvent(mobileNumberBTN)}
        />
        <ItemContainer
          title={'Gift Message'}
          subTitle={giftMessage}
          iconAlpha={iconAlphaGiftMessage}
          iconBeta={iconBeta}
          onClick={() => ClickEvent(giftMessageBTN)}
        />
        <ItemContainer
          title={'Delivery'}
          subTitle={subTitle}
          iconAlpha={CalendarSchedule}
          iconBeta={iconBeta}
          onClick={() => ClickEvent(scheduleDeliveryBTN)}
          isAestTimeZone={!isNow}
          mb={0}
        />
      </Flex>
    );
  };

  return (
    <Flex flexDirection="column" style={Containers.column}>
      <Flex flexDirection="column">
        {updating ? null : <StepBar currentStep={4} />}
        <SheetHeaderLabel label={header} subLabel={subheader} />
      </Flex>
      {renderItems()}
      <Flex>
        <ButtonSheet
          label="Continue"
          withBack
          onPress={onClickContinue}
          onPressBack={onClickBack}
          backButtonStyle={{ marginTop: 24 }}
          buttonStyle={{ marginTop: 24 }}
        />
      </Flex>
    </Flex>
  );
};

export default ReviewOrderScreen;

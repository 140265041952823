import { Theme } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import GraphemeSplitter from 'grapheme-splitter';
import { ChangeEvent, FC, FocusEvent } from 'react';
import {
  ButtonSheet,
  Label,
  MobileNumber,
  SheetHeaderLabel,
  TextArea,
  TextFieldWithLabel,
} from '../../components/AppClip';
import Flex from '../../components/Common/Flex';
import {
  COUNTRY_CODE,
  FIELD_IDS,
  MAX_CHARACTERS,
  REGEX_PATTERNS,
  RecipientDetail as RecipientDetailConstants,
  SCREEN_IDS,
} from '../../constants';
import { RootState, recipientActions, screenActions, useAppDispatch, useAppSelector } from '../../store';
import { ValidateInputProps } from '../../types';
import { capitalizeFirstLetter, onChangeMobileNumber } from '../../utils/helpers';

const { selectAmountScreen, reviewOrderScreen, scheduleDeliveryScreen } = SCREEN_IDS;
const { setRecipient } = recipientActions;
const { setScreen } = screenActions;
const { header } = RecipientDetailConstants;

const styles = {
  textAreaNote: (theme: Theme, isError: boolean, noRemainingChars: boolean) => ({
    ...theme.typography.caption,
    alignSelf: 'flex-end' as const,
    color: noRemainingChars ? theme.palette.error.main : theme.palette.common.black,
    fontSize: '12px',
    lineHeight: '16px',
    marginTop: isError ? '-1px' : '1em',
    marginBottom: 0,
    marginRight: 8,
    opacity: 0.6,
    textAlign: 'right' as const,
  }),
};

const RecipientDetails: FC = () => {
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const { recipientDetails } = useAppSelector((state: RootState) => state.recipient);
  const { updating, fieldsIsFocus } = useAppSelector((state: RootState) => state.screen.screenStates);
  const {
    firstName,
    lastName,
    mobile,
    mobileNumberDisplay,
    giftMessage,
    firstNameERR,
    lastNameERR,
    mobileNumberERR,
    giftMessageERR,
    giftMessageCharCounter,
  } = recipientDetails;

  const { firstNameId, lastNameId, mobileNumberId, giftMessageId } = FIELD_IDS.recipient;

  const onInput = (event: ChangeEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const fieldValue = value.replace(/[^a-z .'-]/gi, '');

    let regexStr = REGEX_PATTERNS.name;
    switch (id) {
      case firstNameId:
        validateInputField({
          regexStr,
          id,
          value: capitalizeFirstLetter(fieldValue),
        });
        break;

      case lastNameId:
        validateInputField({
          regexStr,
          id,
          value: capitalizeFirstLetter(fieldValue),
        });
        break;

      case mobileNumberId:
        regexStr = REGEX_PATTERNS.mobileNumberShort;
        validateInputField({ regexStr, id, value });
        break;

      case giftMessageId:
        validateInputField({ id, value: giftMessage });
        break;

      default:
        break;
    }
  };

  const onTextAreaInput = (event: ChangeEvent<HTMLTextAreaElement>) => {
    const { value } = event.currentTarget;
    const splitter = new GraphemeSplitter(); //package that will handle the emoji unicode count as 1 character.
    if (MAX_CHARACTERS >= splitter.splitGraphemes(value).length) {
      const CharacterRemaining = MAX_CHARACTERS - splitter.splitGraphemes(value).length;
      validateInputField({
        id: giftMessageId,
        value: capitalizeFirstLetter(value),
        counter: CharacterRemaining,
      });
    }
  };

  const getFieldName = (id: string, isValidationError?: boolean) => {
    switch (id) {
      case firstNameId:
      case lastNameId:
        return id.replaceAll('-', ' ');

      case mobileNumberId:
        return isValidationError ? 'Australian mobile number' : 'mobile phone number';

      case giftMessageId:
        return 'a gift message for recipient';

      default:
        return id.replaceAll('-', ' ');
    }
  };

  const validateInputField = (params: ValidateInputProps) => {
    const { regexStr, id = '', value: fieldValue = '', counter = 0, shouldFormatNumber = false } = params;
    let value = fieldValue;
    const fieldName = getFieldName(id);
    let numberToDisplayFormat = '';

    if (id === mobileNumberId) {
      const formattedNumber = onChangeMobileNumber(value);
      value = formattedNumber.mobileNumberId;
      if (shouldFormatNumber) {
        numberToDisplayFormat = `${COUNTRY_CODE} ${formattedNumber.mobileNumberDisplay}`;
      } else {
        numberToDisplayFormat = `${COUNTRY_CODE} ${formattedNumber.mobileNumberId}`;
      }
    }

    let error = '';
    if (!value.length) {
      error = `Please provide ${fieldName}.`;
    } else if (value.length < 2) {
      error = `Please enter a valid ${fieldName}.`;
    } else if (regexStr) {
      const regex = new RegExp(regexStr);
      const isValidInput = regex.test(value);
      if (!isValidInput) {
        error = `Please enter a valid ${getFieldName(id, true)}.`;
      }
    }
    const stringData = error || '';
    dispatch(
      setRecipient({
        firstName: id === firstNameId ? value : firstName,
        lastName: id === lastNameId ? value : lastName,
        mobile: id === mobileNumberId ? value : mobile,
        mobileNumberDisplay: id === mobileNumberId ? numberToDisplayFormat : mobileNumberDisplay,
        giftMessage: id === giftMessageId ? value : giftMessage,
        firstNameERR: id === firstNameId ? stringData : firstNameERR !== undefined ? firstNameERR : '', //suppress undefined then return empty string else value
        lastNameERR: id === lastNameId ? stringData : lastNameERR !== undefined ? lastNameERR : '', //suppress undefine
        mobileNumberERR: id === mobileNumberId ? stringData : mobileNumberERR !== undefined ? mobileNumberERR : '', //suppress undefine
        giftMessageERR: id === giftMessageId ? stringData : giftMessageERR !== undefined ? giftMessageERR : '', //suppress undefine
        giftMessageCharCounter: id === giftMessageId ? counter : giftMessageCharCounter,
      }),
    );
  };

  const stateFocused = (state = false, id = '') => {
    if (!fieldsIsFocus?.recipientDetails?.fields[id]) {
      //Guard
      dispatch(
        setScreen({
          fieldsIsFocus: {
            recipientDetails: {
              fields: {
                [firstNameId]: id === firstNameId ? state : false,
                [lastNameId]: id === lastNameId ? state : false,
                [mobileNumberId]: id === mobileNumberId ? state : false,
                [giftMessageId]: id === giftMessageId ? state : false,
              },
            },
          },
        }),
      );
    }
  };

  const onFocus = (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { id } = event.currentTarget;
    const regexStr = REGEX_PATTERNS.mobileNumberShort;
    document?.getElementById(id)?.focus();
    switch (id) {
      case firstNameId:
      case lastNameId:
      case giftMessageId:
        stateFocused(true, id);
        break;
      case mobileNumberId:
        stateFocused(true, id);
        break;
      default:
        stateFocused(false, id);
        break;
    }
  };

  const onBlur = (event: FocusEvent<HTMLInputElement>) => {
    const { id, value } = event.currentTarget;
    const regexStr = REGEX_PATTERNS.mobileNumberShort;
    if (id === mobileNumberId) {
      validateInputField({ regexStr, id, value, shouldFormatNumber: true });
    }
    stateFocused(false, id);
  };

  const shouldDisableButton = () => {
    if (
      firstName?.length !== 0 &&
      lastName?.length !== 0 &&
      mobile?.length !== 0 &&
      giftMessage?.length !== 0 &&
      firstNameERR?.length === 0 &&
      lastNameERR?.length === 0 &&
      mobileNumberERR?.length === 0 &&
      giftMessageERR?.length === 0
    ) {
      return false;
    }

    return true;
  };

  const onClickContinue = () => {
    if (!shouldDisableButton()) {
      dispatch(
        setScreen({ screen: updating ? reviewOrderScreen : scheduleDeliveryScreen, loading: false, updating: false }),
      );
    }

    if (shouldDisableButton()) {
      dispatch(
        setRecipient({
          firstName: firstName,
          lastName: lastName,
          mobile,
          mobileNumberDisplay: mobileNumberDisplay,
          giftMessage: giftMessage,
          firstNameERR: firstName?.length !== 0 ? firstNameERR : `Please provide ${getFieldName(firstNameId, false)}.`,
          lastNameERR: lastName?.length !== 0 ? lastNameERR : `Please provide ${getFieldName(lastNameId, true)}.`,
          mobileNumberERR:
            mobile?.length !== 0 ? mobileNumberERR : `Please provide ${getFieldName(mobileNumberId, false)}.`,
          giftMessageERR:
            giftMessage?.length !== 0 ? giftMessageERR : `Please provide ${getFieldName(giftMessageId, false)}.`,
          giftMessageCharCounter: giftMessage?.length !== 0 ? giftMessageCharCounter : giftMessageCharCounter,
        }),
      );
    }
  };

  const onClickBack = () => {
    dispatch(
      setScreen({
        screen: selectAmountScreen,
        loading: false,
        updating: false,
        fieldsIsFocus: {
          recipientDetails: {
            fields: {
              [firstNameId]: false,
              [lastNameId]: false,
              [mobileNumberId]: false,
              [giftMessageId]: false,
            },
          },
        },
      }),
    );
  };

  return (
    <Flex
      style={{
        flexDirection: 'column',
      }}
    >
      <SheetHeaderLabel
        label={header}
        subLabel={updating ? '' : 'A Gift Card will be sent via SMS and linked to the recipients mobile number'}
      />
      <Flex
        style={{
          flexDirection: 'column',
        }}
      >
        <TextFieldWithLabel
          id={firstNameId}
          label="First name"
          htmlFor={firstNameId}
          placeholder="First name"
          type="text"
          maxLength={30}
          onInput={onInput}
          onFocus={onFocus}
          value={firstName}
          errorMessage={firstNameERR}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[firstNameId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[firstNameId]} // autofocus will solve the issue with isFocusField in relation with blur not triggering
          required
        />
        <TextFieldWithLabel
          id={lastNameId}
          label="Last name"
          htmlFor={lastNameId}
          placeholder="Last name"
          type="text"
          maxLength={30}
          onInput={onInput}
          onFocus={onFocus}
          value={lastName}
          errorMessage={lastNameERR}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[lastNameId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[lastNameId]}
          required
        />
        <MobileNumber
          id={mobileNumberId}
          label="Mobile number"
          htmlFor={mobileNumberId}
          placeholder="400 000 000"
          type="tel"
          value={mobileNumberDisplay.slice(4)}
          errorMessage={mobileNumberERR}
          onInput={onInput}
          onFocus={onFocus}
          onBlur={onBlur}
          isFocusField={fieldsIsFocus?.recipientDetails?.fields[mobileNumberId]}
          autoFocus={fieldsIsFocus?.recipientDetails?.fields[mobileNumberId]}
          containerStyle={{
            marginTop: '-3px',
          }}
        />
        <Flex
          style={{
            flexDirection: 'column',
            marginTop: '-3px',
          }}
        >
          <Label label="Gift message" htmlFor={giftMessageId} />
          <TextArea
            id={giftMessageId}
            placeholder="What do you want to say?"
            rows={4}
            cols={50}
            onInput={onTextAreaInput}
            onFocus={onFocus}
            isFocusField={fieldsIsFocus?.recipientDetails?.fields[giftMessageId]}
            autoFocus={fieldsIsFocus?.recipientDetails?.fields[giftMessageId]}
            errorMessage={giftMessageERR || ''}
            value={giftMessage}
          />
          <p style={styles.textAreaNote(theme, !!giftMessageERR, giftMessageCharCounter === 0)}>
            {giftMessageCharCounter} characters remaining
          </p>
        </Flex>
      </Flex>
      <ButtonSheet
        label={updating ? 'Update' : 'Continue'}
        withBack={!updating}
        onPress={onClickContinue}
        onPressBack={onClickBack}
        backButtonStyle={{ marginTop: 24 }}
        buttonStyle={{ marginTop: 24 }}
      />
    </Flex>
  );
};

export default RecipientDetails;

import { useEffect, useState } from 'react';
import { APP_IMAGES, AppConfig } from '../constants';
import { useIsMobileView } from './useIsMobileView';

export const useAppImages = () => {
  const isMobile = useIsMobileView();
  const [spinner, setSpinnerSrc] = useState('');
  const [logo, setLogoSrc] = useState('');
  const [card, setCardSrc] = useState('');
  const [background, setBackgroundSrc] = useState('');

  useEffect(() => {
    const background = isMobile ? APP_IMAGES.bannerXs : APP_IMAGES.bannerLg;
    const card = isMobile ? APP_IMAGES.cardXs : APP_IMAGES.cardLg;
    const logo = APP_IMAGES.logo;
    const spinner = APP_IMAGES.spinner;
    setBackgroundSrc(
      `${AppConfig.CDN_BASE_URL}/${AppConfig.PARTNER}/${AppConfig.DEFAULT_MERCHANT_NAME}/${background}.png`,
    );
    setCardSrc(`${AppConfig.CDN_BASE_URL}/${AppConfig.PARTNER}/${AppConfig.DEFAULT_MERCHANT_NAME}/${card}.png`);
    setLogoSrc(`${AppConfig.CDN_BASE_URL}/${AppConfig.PARTNER}/${AppConfig.DEFAULT_MERCHANT_NAME}/${logo}.png`);
    setSpinnerSrc(`${AppConfig.CDN_BASE_URL}/${AppConfig.PARTNER}/${AppConfig.DEFAULT_MERCHANT_NAME}/${spinner}.gif`);
  }, [isMobile]);

  return { logo, background, card, spinner };
};
